<template>
  <banner text="Hausaufgaben" />

  <sui-button-group class="small">
    <sui-button basic v-for="button in buttons" :active="isActive(button)" :key="button" :id="button"
      @click="select(button)">
      {{ button }}
    </sui-button>
  </sui-button-group>
  <br>

  <template v-if="hausaufgabenList">
    <div v-if="isAktuell" class="ui header medium">Zu erledigen</div>
    <div v-if="isHeute" class="ui header medium">
      Aufgegeben heute ({{ weekdayToday() }}, {{ formatDateGermanToday() }})
    </div>

    <div v-if="isVergangen" class="ui header medium">
      <template v-if="hausaufgabenPastData.length > 0">
        <sui-menu pagination class="tiny max-w-30" :widths="paginationWidths">
          <sui-menu-item @click="firstPage">
            <i class="icon angle double left small" :class="{ grey: disableBack }"></i>
          </sui-menu-item>
          <sui-menu-item @click="decPage">
            <i class="icon angle left small" :class="{ grey: disableBack }"></i>
          </sui-menu-item>

          <template v-for="page in pages" :key="page">
            <sui-menu-item :active="isActivePage(page)" @click="selectPage(page)">
              {{ page }}
            </sui-menu-item>
          </template>

          <sui-menu-item @click="incPage">
            <i class="icon angle right small" :class="{ grey: disableForth }"></i>
          </sui-menu-item>
          <sui-menu-item @click="lastPage">
            <i class="icon angle double right small" :class="{ grey: disableForth }"></i>
          </sui-menu-item>
        </sui-menu>
      </template>
    </div>

    <div v-if="isVergangen && hausaufgabenPastData.length === 0">
      <p><i>Keine vergangenen Hausaufgaben vorhanden</i></p>
    </div>

    <div v-if="!isVergangen && hausaufgabenList.length === 0">
      <p v-if="isAktuell"><i>Keine aktuellen Hausaufgaben vorhanden</i></p>
      <p v-if="isHeute"><i>Keine Hausaufgaben von heute vorhanden</i></p>
    </div>
    <p v-if="hausaufgabenList.length > 0 && hausaufgabenToDo.length === 0 && !isVergangen"><i>Keine
        offenen Hausaufgaben</i></p>
  </template>

  <template v-if="isAktuell">
    <loader-local :active="loadingGlobal" />
  </template>

  <template v-if="!isVergangen">
    <hausaufgaben-list :hausaufgabenList="hausaufgabenToDo" :active="active" :dueDateMap="setDueDateMap()">
    </hausaufgaben-list>
  </template>

  <template v-if="isVergangen && !loading">
    <hausaufgaben-list :hausaufgabenList="hausaufgabenPastData" :active="active" :dueDateMap="setDueDateMap()">
    </hausaufgaben-list>
  </template>

  <template v-if="isVergangen && loading">
    <loader-local :active="true" />
  </template>

  <template v-if="hausaufgabenList && !isVergangen">
    <div v-if="hausaufgabenList.length > 0" class="ui header medium pt-1">Erledigt</div>
    <p v-if="hausaufgabenList.length > 0 && hausaufgabenCompleted.length === 0">
      <i>Noch keine Hausaufgabe als erledigt markiert</i>
    </p>
  </template>

  <template v-if="!isVergangen">
    <hausaufgaben-list :hausaufgabenList="hausaufgabenCompleted" :active="active">
    </hausaufgaben-list>
  </template>
</template>
<script>
import Banner from "../components/Banner";
import HausaufgabenList from "../components/HausaufgabenList";
import LoaderLocal from "../components/LoaderLocal";
import store from "../store";
import moment from "moment";

const today = moment().format("YYYY-MM-DD");
const tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
const dayAfterTomorrow = moment().add(2, 'days').format("YYYY-MM-DD");

export default {
  components: { Banner, LoaderLocal, HausaufgabenList },
  data: function () {
    return {
      loadingGlobal: false,
      loadingMap: {},
      date: moment(),
      dueDateMap: [],
      buttons: ['Heute', 'Aktuell', 'Vergangen'],
      active: 'Aktuell',
      hausaufgaben: null,
      hausaufgabenPastData: null,
      hausaufgabenPastPagination: null,
      currentPage: 1,
      maxVisiblePages: 5
    };
  },
  methods: {
    weekdayToday: function () {
      return new Date(today).toLocaleString('de-de', { weekday: 'long' });
    },
    formatDateGermanToday: function () {
      return moment(today).format('DD.MM.');
    },
    setDueDateMap: function () {
      if (this.hausaufgabenList != null) {
        if (this.hausaufgabenToDo != null && this.isAktuell) {
          this.dueDateMap['today'] = this.mapDueAt(today);
          this.dueDateMap['tomorrow'] = this.mapDueAt(tomorrow);
          this.dueDateMap['dayAfterTomorrow'] = this.mapDueAt(dayAfterTomorrow);

          let dueDatesLater = this.hausaufgabenToDo.filter(hausaufgabe => hausaufgabe.due_at > dayAfterTomorrow);
          if (dueDatesLater.length > 0) {
            this.dueDateMap['laterTime'] = this.mapDueAt(dueDatesLater[0]['due_at']);
          }
          let noDueDate = this.hausaufgabenToDo.filter(hausaufgabe => hausaufgabe.due_at == null);
          if (noDueDate.length > 0) {
            this.dueDateMap['noDueDate'] = this.mapDueAt(noDueDate[0]['due_at']);
          }
          return this.dueDateMap;
        }
      }
    },
    mapDueAt: function (dueAt) {
      this.hausaufgabenToDo.filter(hausaufgabe => hausaufgabe.due_at > null);
      return this.hausaufgabenToDo.map(hausaufgabe => hausaufgabe.due_at).indexOf(dueAt);
    },
    getHausaufgaben: function () {
      this.loadingGlobal = true;
      store.getters['hausaufgaben/getHausaufgaben'].subscribe(
        (hausaufgaben) => {
          if (hausaufgaben !== null) {
            this.hausaufgaben = hausaufgaben;
            this.loadingGlobal = false;
          }
        },
        (error) => {
          // 401 redirects to login anyway
          if (error.response.status !== 401) {
            alert('Fehler beim Laden der Hausaufgaben.');
            this.loadingGlobal = false;
          }
        });
    },
    getHausaufgabenPast: function () {
      this.setLoading(this.currentPage, true);
      store.getters['hausaufgaben/getHausaufgabenPast'](this.currentPage)
        .subscribe((hausaufgabenPastMap) => {
          if (hausaufgabenPastMap !== null) {
            this.hausaufgabenPastData = hausaufgabenPastMap.data;
            this.hausaufgabenPastPagination = hausaufgabenPastMap.pagination;
            this.setLoading(this.currentPage, false);
          }
        }, (error) => {
          // 401 redirects to login anyway
          if (error.response.status !== 401) {
            alert('Fehler beim Laden der Hausaufgaben.');
            this.setLoading(this.currentPage, true);
          }
        });
    },
    setLoading: function (pageNumber, loading) {
      this.loadingMap[pageNumber] = loading;
    },
    isActive: function (button) {
      return this.active === button;
    },
    select: function (button) {
      this.active = button;
    },
    isActivePage: function (page) {
      return this.currentPage === page;
    },
    selectPage: function (page) {
      this.currentPage = page;
      return this.hausaufgabenPastData;
    },
    decPage: function () {
      if (this.currentPage > 1) {
        this.currentPage = this.currentPage - 1;
        return this.hausaufgabenPastData;
      }
    },
    incPage: function () {
      if (this.currentPage < this.hausaufgabenPastPagination.last_page) {
        this.currentPage = this.currentPage + 1;
        return this.hausaufgabenPastData;
      }
    },
    firstPage: function () {
      this.currentPage = 1;
    },
    lastPage: function () {
      this.currentPage = this.hausaufgabenPastPagination.last_page;
    },
  },
  computed: {
    isHeute: function () {
      return this.active === this.buttons[0];
    },
    isAktuell: function () {
      return this.active === this.buttons[1];
    },
    isVergangen: function () {
      return this.active === this.buttons[2];
    },
    disableBack: function () {
      return this.currentPage === 1;
    },
    disableForth: function () {
      return this.currentPage === this.hausaufgabenPastPagination.last_page;
    },
    loading: function () {
      return this.loadingMap[this.currentPage];
    },
    paginationWidths: function () {
      const fixedWidth = 4;
      if (this.totalPages >= 1 && this.totalPages < 6) {
        return fixedWidth + this.totalPages;
      }
      if (this.totalPages > 6) {
        return fixedWidth + this.maxVisiblePages;
      }
      return null;
    },
    totalPages: function () {
      return this.hausaufgabenPastPagination.last_page;
    },
    startPage() {
      if (this.currentPage < this.maxVisiblePages) {
        return 1;
      }
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisiblePages + 1;
      }
      if (this.currentPage === this.totalPages - 1) {
        return this.totalPages - this.maxVisiblePages + 1;
      }
      return this.currentPage - 2;
    },
    pages() {
      const range = [];

      let min = 1;
      let max = this.totalPages;

      if (this.totalPages > this.maxVisiblePages) {
        min = this.startPage;
        max = Math.min(this.startPage + this.maxVisiblePages - 1, this.totalPages);
      }

      for (let i = min; i <= max; i++) {
        range.push(i);
      }
      return range;
    },
    hausaufgabenList: function () {
      switch (this.active) {
        case 'Aktuell':
          return this.hausaufgabenSorted;
        case 'Heute':
          return this.hausaufgabenToday;
        case 'Vergangen':
          this.getHausaufgabenPast();
          return this.hausaufgabenPastData;
        default:
          return null;
      }
    },
    hausaufgabenToDo: function () {
      if (this.hausaufgabenList != null) {
        return this.hausaufgabenList.filter((hausaufgabe) => !hausaufgabe.completed);
      }
      return null;
    },
    hausaufgabenCompleted: function () {
      if (this.hausaufgabenList != null) {
        return this.hausaufgabenList.filter((hausaufgabe) => hausaufgabe.completed);
      }
      return null;
    },
    hausaufgabenSorted: function () {
      if (this.hausaufgaben != null) {
        let sortedHausaufgaben = this.hausaufgaben;
        return sortedHausaufgaben.sort((a, b) => {
          if (a.due_at < b.due_at) return -1;
          if (a.due_at > b.due_at) return 1;
          return 0;
        });
      }
      return null;
    },
    hausaufgabenToday: function () {
      if (this.hausaufgaben != null) {
        return this.hausaufgaben.filter((hausaufgaben) => hausaufgaben.date === today);
      }
      return null;
    },
  },
  created() {
    this.getHausaufgaben();
  },
};
</script>