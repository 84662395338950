<template>
  <banner :text="'Hausaufgabe in ' + hausaufgabe.subject.long" v-if="hausaufgabeExists"/>
  <banner text="Hausaufgabe" v-else/>

  <sui-segment
      :class="{green: hausaufgabeExists && hausaufgabe.completed, yellow: hausaufgabe && !hausaufgabe.completed}">
    <loader-local :active="loading"/>

    <template v-if="hausaufgabeExists">

      <h3>{{ hausaufgabe.homework }}</h3>
      <div>
        <small>
          aufgegeben von {{ hausaufgabe.teacher }} am {{ formatDate(hausaufgabe.date) }}
        </small>
      </div>

      <attachment-list :files="hausaufgabe.files"/>

      <h5 v-if="hausaufgabe.due_at">
        fällig am {{ formatDate(hausaufgabe.due_at) }}
      </h5>

      <template v-if="hausaufgabe.submissions.length > 0 && !adminModeIsActive">
        <sui-segment class="secondary" v-for="(submission, key) of hausaufgabe.submissions" :key="key">
          <small>abgegeben am {{ formatDate(submission.schuelerSubmittedAt) }}</small>
          <div v-if="submission.schuelerComment">
            {{ submission.schuelerComment }}
          </div>

          <attachment-list :files="submission.submissionFiles"/>

          <template v-if="submission.teacherCorrectedAt">
            <hr/>
            <small>
              Kommentar von {{ submission.teacher ?? hausaufgabe.teacher }}
              am {{ formatDate(submission.schuelerSubmittedAt) }}
            </small>
            <div v-if="submission.teacherCorrection">
              {{ submission.teacherCorrection }}
            </div>

            <attachment-list :files="submission.correctionFiles"/>
          </template>
        </sui-segment>
      </template>

      <sui-segment v-else-if="hausaufgabe.back">
        <p>Hausaufgabe abgeben</p>

        <form class="ui form" @submit.prevent="submitHomework">
          <div class="field">
            <label>Bemerkung</label>
            <textarea rows="5" class="resize-none" v-model="submissionForm.comment"/>
          </div>

          <div class="field">
            <label class="pointer" @click="onPickSubmissionFile">
              Anhänge
              <i class="plus circle green icon" title="Anhang hinzufügen"
                 v-if="submissionForm.files.length < maxSubmissionFiles"/>
            </label>
            <input type="file" class="d-none"
                   ref="submissionFileInput" @change="onSubmissionFilePicked"/>

            <div class="ui list" v-if="submissionForm.files.length > 0">
              <div class="item" v-for="(file, key) of submissionForm.files" :key="key">
                <div class="content">
                  <i class="paperclip icon"></i>
                  {{ file.name }}
                  <i class="trash red icon pointer" @click="deleteSubmissionFile(key)"/>
                </div>
              </div>
            </div>
          </div>

          <button class="ui blue icon button" :class="{'loading disabled': submitting, disabled: adminModeIsActive}">
            abschicken
            <i class="paper plane icon"/>
          </button>
        </form>
      </sui-segment>

      <template v-if="hausaufgabe.submissions.length === 0 && !hausaufgabe.back">
        <button class="ui green icon button" @click="toggleCompleted" v-if="!hausaufgabe.completed"
                :class="{'loading disabled': toggelingCompleted, disabled: adminModeIsActive}">
          als erledigt markieren
          <i class="thumbs up icon"/>
        </button>
        <button class="ui yellow icon button" @click="toggleCompleted" v-else
                :class="{'loading disabled': toggelingCompleted, disabled: adminModeIsActive}">
          als nicht erledigt markieren
          <i class="thumbs down icon"/>
        </button>
      </template>

    </template>

  </sui-segment>

</template>

<script>
import Banner from "../components/Banner";
import LoaderLocal from "../components/LoaderLocal";
import AttachmentList from "../components/AttachmentList";
import store from "../store";
import moment from "moment";

export default {
  components: {Banner, LoaderLocal, AttachmentList},
  data: function () {
    return {
      loading: false,
      hausaufgabe: null,
      submissionForm: {
        comment: null,
        files: []
      },
      maxSubmissionFiles: 3,
      submitting: false,
      toggelingCompleted: false
    };
  },
  methods: {
    getHausaufgabe: function (homeworkId) {
      this.loading = true;
      store.getters['hausaufgaben/getHausaufgabe'](homeworkId)
          .subscribe((hausaufgabe) => {
            this.hausaufgabe = hausaufgabe;
            this.loading = !hausaufgabe;
          }, (error) => {
            // 401 redirects to login anyway
            if (error.response.status !== 401) {
              alert('Fehler beim Laden der Hausaufgabe.');
              this.loading = false;
            }
          });
    },
    onPickSubmissionFile() {
      if (this.submissionForm.files.length < this.maxSubmissionFiles) {
        this.$refs.submissionFileInput.click();
      } else {
        alert('Maximal ' + this.maxSubmissionFiles + ' Anhänge möglich');
      }
    },
    onSubmissionFilePicked(event) {
      const file = event.target.files[0];

      this.submissionForm.files.push(file);
    },
    deleteSubmissionFile(key) {
      this.submissionForm.files.splice(key, 1);
    },
    toggleCompleted: function () {
      this.toggelingCompleted = true;
      store.dispatch('hausaufgaben/toggleCompleted', this.hausaufgabe.id)
          .then(() => this.toggelingCompleted = false)
          .catch(() => this.toggelingCompleted = false);
    },
    submitHomework: function () {
      if (!this.submissionForm.comment
          && this.submissionForm.files.length === 0) {
        alert('Gib mindestens eine Bemerkung ein oder wähle einen Anhang aus');
        return;
      }

      if (!confirm('Bist du dir sicher?\nDu kannst nur einmal abgeben.')) {
        return;
      }

      let formData = new FormData();

      if (this.submissionForm.comment) {
        formData.append('comment', this.submissionForm.comment);
      }
      for (let i = 0; i < this.submissionForm.files.length; i++) {
        formData.append('files[' + i + ']', this.submissionForm.files[i]);
      }

      this.submitting = true;
      store.dispatch('hausaufgaben/submit', {
        hausaufgabe: this.hausaufgabe,
        formData: formData
      })
          .finally(() => this.submitting = false);
    },
    formatDate: function (dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    }
  },
  computed: {
    hausaufgabeExists: function () {
      return this.hausaufgabe && Object.keys(this.hausaufgabe).length > 0;
    },
    adminModeIsActive: function () {
      return store.getters['auth/adminModeIsActive'];
    }
  },
  created() {
    this.getHausaufgabe(this.$route.params.homeworkId);
  },
};
</script>
