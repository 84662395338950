import axios from "axios";
import store from "../index";
import {BehaviorSubject} from "rxjs";

export default {
    namespaced: true,
    state: {
        hausaufgaben: new BehaviorSubject(null),
        hausaufgabenPastMap: {},
        hausaufgabe: new BehaviorSubject(null),
        listeningForUpdate: false
    },
    getters: {
        getHausaufgaben: (state) => {
            if (state.hausaufgaben.getValue() === null) {
                store.dispatch('hausaufgaben/fetchHausaufgaben');
                store.commit('hausaufgaben/listenToUpdates');
            }
            return state.hausaufgaben;
        },
        getHausaufgabenPast: (state) => (pageNumber) => {
            if (!state.hausaufgabenPastMap[pageNumber]) {
                state.hausaufgabenPastMap[pageNumber] = new BehaviorSubject(null);
            }
            if (state.hausaufgabenPastMap[pageNumber].getValue() === null) {
                store.dispatch('hausaufgaben/fetchHausaufgabenPast', pageNumber);
            }
            return state.hausaufgabenPastMap[pageNumber];
        },
        getHausaufgabe: (state) => (homeworkId) => {
            let haList = state.hausaufgaben.getValue();

            // Search loaded current homeworks
            if (haList) {
                for (let ha of haList) {
                    if (ha.id === parseInt(homeworkId)) {
                        state.hausaufgabe.next(ha);
                        return state.hausaufgabe;
                    }
                }
            }

            // Search loaded past homeworks
            for (let pageNumber of Object.keys(state.hausaufgabenPastMap)) {
                for (let ha of state.hausaufgabenPastMap[pageNumber].getValue().data) {
                    if (ha.id === parseInt(homeworkId)) {
                        state.hausaufgabe.next(ha);
                        return state.hausaufgabe;
                    }
                }
            }

            // Is the requested homework the one being currently assigned
            if (state.hausaufgabe.getValue() && state.hausaufgabe.getValue().id === parseInt(homeworkId)) {
                return state.hausaufgabe;
            }

            // Fetch from server if not found locally
            state.hausaufgabe.next(null);
            store.dispatch('hausaufgaben/fetchHausaufgabe', homeworkId);
            store.commit('hausaufgaben/listenToUpdates');
            return state.hausaufgabe;
        },
    },
    mutations: {
        listenToUpdates(state) {
            if (!state.listeningForUpdate) {
                store.commit('dataUpdates/subscribe', {
                    dataType: 'hausaufgaben',
                    callback: () => {
                        // Update hausaufgaben if loaded
                        if (state.hausaufgaben.getValue()) {
                            store.dispatch('hausaufgaben/fetchHausaufgaben')
                                .then(() => {
                                    // Set new reference for single hausaufgabe if previously requested
                                    if (state.hausaufgabe.getValue()) {
                                        store.getters['hausaufgaben/getHausaufgabe'](state.hausaufgabe.getValue().id);
                                    }
                                });
                        } else {
                            if (state.hausaufgabe.getValue()) {
                                store.dispatch('hausaufgaben/fetchHausaufgabe', state.hausaufgabe.getValue().id);
                            }
                        }
                    }
                });
            }
            state.listeningForUpdate = true;
        },
        updateHausaufgabe(state, {homeworkId, data}) {
            const hausaufgabe = store.getters['hausaufgaben/getHausaufgabe'](homeworkId).getValue();
            if (hausaufgabe) {
                Object.assign(hausaufgabe, data)
            }
        },
        setHausaufgabenPast(state, {pageNumber, value}) {
            state.hausaufgabenPastMap[pageNumber].next(value);
        }
    },
    actions: {
        fetchHausaufgabe({state, rootGetters}, homeworkId) {
            return axios.get(rootGetters.getUrl('/api/hausaufgabe/' + homeworkId))
                .then(res => res.data)
                .then(hausaufgabe => state.hausaufgabe.next(hausaufgabe))
                .catch((error) => {
                    console.log('error');
                    state.hausaufgabe.error(error);
                });
        },
        fetchHausaufgaben({state, rootGetters}) {
            return axios.get(rootGetters.getUrl('/api/hausaufgaben'))
                .then(res => res.data)
                .then(hausaufgaben => state.hausaufgaben.next(hausaufgaben))
                .catch(state.hausaufgaben.error);
        },
        fetchHausaufgabenPast({state, commit, rootGetters}, pageNumber) {
            return axios.get(rootGetters.getUrl('/api/hausaufgaben/past/sorted/' + pageNumber))
                .then(res => res.data)
                .then(hausaufgaben => {
                    commit('setHausaufgabenPast', {
                        pageNumber: pageNumber,
                        value: hausaufgaben
                    })
                })
                .catch(state.hausaufgabenPastMap[pageNumber].error);
        },
        submit({commit, rootGetters}, {hausaufgabe, formData}) {
            return axios.post(rootGetters.getUrl('/api/hausaufgaben/' + hausaufgabe.id + '/submit'),
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then(res => res.data)
                .then(data => {
                    commit('updateHausaufgabe', {
                        homeworkId: hausaufgabe.id,
                        data: data
                    });
                    return data;
                })
                .catch(console.log);
        },
        toggleCompleted({commit, rootGetters}, homeworkId) {
            return axios.post(rootGetters.getUrl('/api/hausaufgaben/' + homeworkId + '/toggle-completed'))
                .then(res => res.data.completed)
                .then(completed => {
                    if (completed !== undefined) {
                        commit('updateHausaufgabe', {
                            homeworkId: homeworkId,
                            data: {completed: completed}
                        });
                    }
                })
                .catch(console.log);
        },
    },
    modules: {},
};
