<template>

  <i class="bullhorn icon" title="Broadcast (Nur Eigentümer kann Nachrichten verschicken)"
     v-if="chat && chat.broadcast"/>
  <i class="comment icon" v-else/>

</template>

<script>
export default {
  name: 'ChatIcon',
  props: ['chat']
}
</script>