import axios from "axios";
import router from "../../router";
import store from "../index";
import {BehaviorSubject} from "rxjs";

export default {
    namespaced: true,
    state: {
        chats: new BehaviorSubject(null),
        chatsMap: {},
        chatsLoading: false
    },
    getters: {
        getChats: (state) => {
            if (state.chats.getValue() === null && !state.chatsLoading) {
                store.dispatch('chat/fetchChats');
            }
            return state.chats;
        },
        getChat: (state) => (chatId) => {
            if (!state.chatsMap[chatId]) {
                state.chatsMap[chatId] = new BehaviorSubject(null);
            }
            if (state.chatsMap[chatId].getValue() === null) {
                store.dispatch('chat/fetchChat', chatId)
                    .then(() => store.dispatch('chat/markMessagesAsRead', chatId));
            } else {
                store.dispatch('chat/markMessagesAsRead', chatId);
            }

            // fetch chats if necessary to subscribe to new messages
            store.getters['chat/getChats'];

            return state.chatsMap[chatId];
        }
    },
    mutations: {
        receiveNewMessage(state, {chatId, message}) {
            const chatSubject = state.chatsMap[chatId];
            if (chatSubject && chatSubject.getValue() !== null) {
                const chatDetails = chatSubject.getValue();
                chatDetails.messages.push(message);
                chatSubject.next(chatDetails);
            }

            const chats = state.chats.getValue();
            if (chats) {
                const chatOverview = chats.find((chat) => chat.id === chatId);

                if (chatOverview) {
                    if (message.editor.id !== store.state.auth.user.id) {
                        chatOverview.unreadMessagesCount++;
                    }
                    chatOverview.latestMessage = {
                        timestamp: message.createdAt,
                        text: message.text,
                        file: message.file?.name
                    };
                    state.chats.next(chats);
                }
            }

            // If chat is open, mark as read
            if (chatSubject && chatSubject.getValue() !== null && chatSubject.observed) {
                store.dispatch('chat/markMessagesAsRead', chatId);
            }
        },
        registerUpdatedMessage(state, {chatId, message}) {
            const chatSubject = state.chatsMap[chatId];
            if (chatSubject && chatSubject.getValue() !== null) {
                const chatDetails = chatSubject.getValue();

                chatDetails.messages.forEach(function (existingMessage, index) {
                    if (existingMessage.id === message.id) {
                        chatDetails.messages[index] = message;
                    }
                });
                chatSubject.next(chatDetails);
            }

            const chats = state.chats.getValue();
            if (chats) {
                const chatOverview = chats.find((chat) => chat.id === chatId);

                if (chatOverview && chatOverview.latestMessage.timestamp === message.createdAt) {
                    chatOverview.latestMessage.text = message.text;
                    state.chats.next(chats);
                }
            }

            // If chat is open, mark as read
            if (chatSubject && chatSubject.getValue() !== null && chatSubject.observed) {
                store.dispatch('chat/markMessagesAsRead', chatId);
            }
        },
        registerNewChat(state, chat) {
            const chatOverview = {
                id: chat.id,
                name: chat.name,
                createdAt: chat.createdAt,
                owner: chat.owner,
                unreadMessagesCount: 0,
                latestMessage: null
            }

            let chats = state.chats.getValue();
            if (chats === null) {
                chats = [chatOverview];
            } else {
                chats.unshift(chatOverview);
            }

            state.chats.next(chats);
        },
        registerDeletedChat(state, deletedChat) {
            let chats = state.chats.getValue();
            chats = chats.filter((chat) => {
                return chat.id !== deletedChat.id
            });

            if (parseInt(router.currentRoute.value.params.chatId ?? 0) === deletedChat.id) {
                router.push('/chat');
            }

            state.chats.next(chats);
        },
        registerUpdatedChat(state, chat) {
            const chatSubject = state.chatsMap[chat.id];
            if (chatSubject) {
                chatSubject.next(chat);
            }

            const chats = state.chats.getValue();
            if (chats) {
                const chatOverview = chats.find((chatOv) => chatOv.id === chat.id);

                if (chatOverview) {
                    Object.assign(chatOverview, chat);
                    state.chats.next(chats);
                }
            }
        }
    },
    actions: {
        fetchChats({state, dispatch, rootGetters}) {
            state.chatsLoading = true;

            return axios.get(rootGetters.getUrl('/api/chat'))
                .then((res) => {
                    // These action can be processed asynchronously
                    dispatch('subscribeToNewMessages');
                    dispatch('subscribeToUpdatedMessages');
                    dispatch('subscribeToNewChats');
                    dispatch('subscribeToUpdatedChats');
                    dispatch('subscribeToDeletedChats');

                    return res.data;
                })
                .then((chats) => state.chats.next(chats))
                .catch((error) => state.chats.error(error))
                .finally(() => state.chatsLoading = false);
        },
        markMessagesAsRead({state, dispatch}, chatId) {
            // chat overview
            const chats = state.chats.getValue();
            if (chats) {
                const chatOverview = chats.find((chat) => chat.id === parseInt(chatId));

                if (chatOverview) {
                    chatOverview.unreadMessagesCount = 0;
                    state.chats.next(chats);
                }
            }

            // chat details
            const chatSubject = state.chatsMap[chatId];
            if (chatSubject) {
                const chat = chatSubject.getValue();
                chat.messages.map((msg) => msg.read = true);

                chatSubject.next(chat);
            }

            // Async
            dispatch('reportMessagesRead', chatId);
        },
        reportMessagesRead({rootGetters}, chatId) {
            return axios.post(rootGetters.getUrl('/api/chat/' + chatId + '/read'))
        },
        togglePinChat({rootGetters}, chat) {
            return axios.post(rootGetters.getUrl('/api/chat/' + chat.id + '/toggle-pin'), chat);
        },
        subscribeToNewMessages({commit, rootState, rootGetters}) {
            rootGetters['websocket/Echo']
                .private('chats-for-user.' + rootState.auth.user.id)
                .listen('NewChatMessageForUser', (data) => {
                    commit('receiveNewMessage', data);
                });
        },
        subscribeToUpdatedMessages({commit, rootState, rootGetters}) {
            rootGetters['websocket/Echo']
                .private('chats-for-user.' + rootState.auth.user.id)
                .listen('ChatMessageUpdatedForUser', (messageData) => {
                    commit('registerUpdatedMessage', messageData);
                });
        },
        subscribeToNewChats({commit, rootState, rootGetters}) {
            rootGetters['websocket/Echo']
                .private('chats-for-user.' + rootState.auth.user.id)
                .listen('NewChatMembershipForUser', (chat) => {
                    commit('registerNewChat', chat);
                });
        },
        subscribeToUpdatedChats({commit, rootState, rootGetters}) {
            rootGetters['websocket/Echo']
                .private('chats-for-user.' + rootState.auth.user.id)
                .listen('ChatUpdatedForUser', (chat) => {
                    commit('registerUpdatedChat', chat);
                });
        },
        subscribeToDeletedChats({commit, rootState, rootGetters}) {
            rootGetters['websocket/Echo']
                .private('chats-for-user.' + rootState.auth.user.id)
                .listen('ChatDeletedForUser', (chat) => {
                    commit('registerDeletedChat', chat);
                });
        },
        createChat({commit, rootGetters}, chatData) {
            return axios.post(rootGetters.getUrl('/api/chat'), chatData)
                .then(res => res.data)
                .then(chat => {
                    commit('registerNewChat', chat);
                    return chat;
                })
                .catch(() => alert('Fehler beim Speichern des Chats. Bitte versuchen Sie es erneut.'));
        },
        fetchChat({state, rootGetters}, chatId) {
            return axios.get(rootGetters.getUrl('/api/chat/' + chatId))
                .then(res => res.data)
                .then(chat => state.chatsMap[chatId].next(chat))
                .catch(error => state.chatsMap[chatId].error(error));
        },
        updateChat({commit, rootGetters}, chat) {
            return axios.put(rootGetters.getUrl('/api/chat/' + chat.id), chat)
                .then(res => res.data)
                .then(chat => commit('registerUpdatedChat', chat))
                .catch(() => alert('Fehler beim Speichern des Chats. Bitte versuchen Sie es erneut.'));
        },
        leaveChat({rootGetters}, chatId) {
            rootGetters['websocket/Echo']
                .leave(`chat.` + chatId);
        },
        getAllPotentialMembers({rootGetters}, chatId) {
            return axios.get(rootGetters.getUrl('/api/chat/' + chatId + '/member'))
                .then(res => res.data)
                .catch(() => alert('Fehler bei Laden der möglichen Chat Mitglieder. Bitte versuchen Sie es erneut.'));
        },
        addMember({rootGetters}, {chatId, member}) {
            return axios.post(rootGetters.getUrl('/api/chat/' + chatId + '/member'), {
                member_type: member.value.member_type,
                member_id: member.value.member_id
            })
                .catch(() => alert('Fehler beim Hinzufügen des Mitglieds. Bitte versuchen Sie es erneut.'));
        },
        removeMember({rootGetters}, memberId) {
            return axios.delete(rootGetters.getUrl('/api/chat/member/' + memberId))
                .catch(() => alert('Fehler beim Entfernen des Mitglieds. Bitte versuchen Sie es erneut.'));
        },
        toggleAdmin({rootGetters}, memberId) {
            return axios.put(rootGetters.getUrl('/api/chat/member/' + memberId + '/admin'))
                .catch(() => alert('Fehler beim Setzen des Adminstatus des Mitglieds. Bitte versuchen Sie es erneut.'));
        },
        deleteChat({commit, rootGetters}, chat) {
            commit('setLoading', true, {root: true});

            return axios.delete(rootGetters.getUrl('/api/chat/' + chat.id))
                .then(() => {
                    commit('registerDeletedChat', chat);
                    router.push('/chat');
                })
                .catch(() => alert('Fehler beim Löschen des Chats. Bitte versuchen Sie es erneut.'))
                .finally(() => commit('setLoading', false, {root: true}));
        },
        deleteChatMessage({commit, rootGetters}, {messageId}) {
            commit('setLoading', true, {root: true});

            return axios.delete(rootGetters.getUrl('/api/chat/message/' + messageId))
                .then(res => res.data)
                .catch(() => alert('Fehler beim Löschen der Chatnachricht. Bitte versuchen Sie es erneut.'))
                .finally(() => commit('setLoading', false, {root: true}));
        },
        postMessage({rootGetters}, {chatId, formData}) {
            return axios.post(rootGetters.getUrl('/api/chat/' + chatId + '/message'),
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
                .then(res => res.data);

        }
    },
    modules: {}
}
