<template>
  <sui-segment loading class="border-0 box-shadow-0" v-if="active"/>
</template>

<script>
export default {
  name: "LoaderLocal",
  props: ['active']
}
</script>
