import axios from "axios";
import store from "../index";
import LaravelEcho from "laravel-echo";

export default {
    namespaced: true,
    state: {
        Echo: null
    },
    getters: {
        Echo: (state) => {
            return state.Echo;
        }
    },
    mutations: {
        buildEchoInstance(state, wsKey) {
            window.Pusher = require('pusher-js');

            state.Echo = new LaravelEcho({
                broadcaster: 'pusher',
                key: wsKey,
                wsHost: process.env.VUE_APP_WSS_HOST,
                wsPort: process.env.VUE_APP_WSS_PORT,
                wssPort: process.env.VUE_APP_WSS_PORT,
                forceTLS: process.env.VUE_APP_WSS_TLS,
                cluster: '',
                encrypted: false,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
                authorizer: (channel) => {
                    return {
                        authorize: (socketId, callback) => {
                            // use axios instance with current config
                            axios.post(store.getters.getUrl('/broadcasting/auth'), {
                                socket_id: socketId,
                                channel_name: channel.name
                            })
                                .then(response => {
                                    callback(false, response.data);
                                })
                                .catch(error => {
                                    callback(true, error);
                                });
                        }
                    };
                },
            });
        }
    },
    actions: {
        initEcho({state, commit, rootGetters}) {
            if (state.Echo) {
                return;
            }

            commit('setLoading', true, {root: true});
            return axios.get(rootGetters.getUrl('/api/ws-key'))
                .then(res => res.data)
                .then(data => {
                    commit('buildEchoInstance', data.ws_key);
                    commit('dataUpdates/initObserver', null, {root: true})
                })
                .finally(() => commit('setLoading', false, {root: true}));

        }
    },
    modules: {}
}
