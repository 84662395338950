<template>
  <banner text="Unterricht"/>

  <sui-table unstackable definition>
    <sui-table-header>
      <sui-table-row>
        <sui-table-header-cell/>
        <sui-table-header-cell :colspan="columns.length">
          <div class="weekday-container">
            <sui-icon name="chevron circle left" class="pointer" @click="decDate()"/>
            <div class="date-container">
              <div>{{ weekdayName }}</div>
              <div class="ui segment p-0 m-0">
                <div class="datepicker-wrapper ui input tiny pointer">
                  <VueDatePicker v-model="selectedDate" @update:model-value="goToDate" @cleared="resetSelectedDate()"
                                 locale="de" format="dd.MM.yyyy"
                                 :min-date="lowerLimit" :max-date="upperLimit" prevent-min-max-navigation
                                 :disabled-week-days="[6, 0]" :enable-time-picker="false" auto-apply></VueDatePicker>
                </div>
              </div>
            </div>
            <sui-icon name="chevron circle right" class="pointer" @click="incDate()"/>
          </div>
        </sui-table-header-cell>
      </sui-table-row>
    </sui-table-header>

    <sui-table-body v-if="unterrichtMatrix && !loading">
      <sui-table-row v-for="(hour, index) in hours" :key="index">
        <sui-table-cell collapsing>
          <span class="ui small text">
            {{ hour }}
          </span>
        </sui-table-cell>
        <template v-for="(column, index) in columns" :key="index">
          <template v-if="!hourIsOccupied(hour, column)">
            <template v-for="(entry, index) in [getEntryByHour(hour, column)]" :key="index">
              <sui-table-cell :rowspan="entry ? getEntryDuration(entry) : 1" class="table-cell">
                <div v-if="entry">
                  <div class="d-flex justify-between">
                    <b>{{ entry.subject.long }}</b>
                    <small>{{ entry.teacher }}</small>
                  </div>
                  <div v-if="entry.content">
                    <div>
                      {{ entry.content.text }}
                    </div>

                    <attachment-list :files="entry.content.files" class="mb-1"/>
                  </div>

                  <template v-if="entry.homework">
                    <router-link :to="'/hausaufgaben/' + entry.homework.id">
                      <sui-segment class="secondary">
                        <div class="d-flex justify-between">
                          <div>
                            <b>Hausaufgabe</b>
                          </div>

                          <div>
                            <sui-icon name="upload" v-if="entry.homework.back" title="Digitale Rückgabe erwartet"/>

                            <template v-if="entry.homework.files.length > 0">
                              <sui-icon name="paperclip" title="Anhänge"/>
                              {{ entry.homework.files.length }}
                            </template>
                          </div>
                        </div>

                        <div>
                          {{ entry.homework.homework }}
                          <div v-if="entry.homework.due_at">
                            <small>
                              zu erledigen bis {{ formatDateGerman(entry.homework.due_at) }}
                            </small>
                          </div>
                        </div>
                      </sui-segment>
                    </router-link>
                  </template>
                </div>

                <div v-if="unterrichtMatrix[0].length === 0">
                  &empty; keine Einträge für diesen Tag
                </div>
              </sui-table-cell>
            </template>
          </template>
        </template>
      </sui-table-row>
    </sui-table-body>

    <sui-table-body v-else>
      <sui-table-row>
        <sui-table-cell collapsing>
          &nbsp;
        </sui-table-cell>
        <sui-table-cell>
          <loader-local :active="true"/>
        </sui-table-cell>
      </sui-table-row>
    </sui-table-body>
  </sui-table>
</template>

<script>
import Banner from "../components/Banner";
import LoaderLocal from "../components/LoaderLocal";
import AttachmentList from "../components/AttachmentList";
import store from '../store';
import moment from "moment";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {Banner, LoaderLocal, AttachmentList, VueDatePicker},
  data: function () {
    return {
      loadingMap: {},
      unterrichtMatrixMap: {},
      selectedDate: null,
      lowerLimit: null,
      upperLimit: null,
      isToday: false
    }
  },
  methods: {
    getUnterricht: function () {
      const dateString = this.date.format('YYYY-MM-DD');

      this.setLoading(dateString, true);
      this.unterrichtMatrixMap[dateString] = null;
      store.getters['unterricht/getUnterricht']
          .subscribe((data) => {
            if (data !== null) {
              this.unterrichtMatrixMap[dateString] = this.buildUnterrichtMatrix(data);
              this.setLoading(dateString, false);
            }
          }, (error) => {
            // 401 redirects to login anyway
            if (error.response.status !== 401) {
              alert('Fehler beim Laden der Unterrichtsinhalte.');
              this.setLoading(dateString, false);
            }
          });
    },
    buildUnterrichtMatrix: function (unterricht) {
      let matrix = {};
      let leftEntries = unterricht;
      let column = 0;

      do {
        let entries = JSON.parse(JSON.stringify(leftEntries));
        leftEntries = [];
        matrix[column] = [];

        for (let entry of entries) {

          let columnIsOccupied = matrix[column].find((listEntry) => {
            return listEntry.hour_from <= entry.hour_to && listEntry.hour_to >= entry.hour_from;
          });

          if (!columnIsOccupied) {
            matrix[column].push(entry);
          } else {
            leftEntries.push(entry);
          }

        }

        column++;
      } while (leftEntries.length > 0);

      return matrix;
    },
    getEntryByHour: function (hour, column) {
      return this.unterrichtMatrix[column].find((entry) => {
        return entry.hour_from === hour;
      })
    },
    hourIsOccupied: function (hour, column) {
      return this.unterrichtMatrix[column].find((entry) => {
        return entry.hour_from < hour && entry.hour_to >= hour;
      });
    },
    getEntryDuration: function (entry) {
      return entry.hour_to - entry.hour_from + 1;
    },
    setLoading: function (dateString, loading) {
      this.loadingMap[dateString] = loading;
    },
    incDate: function () {
      store.commit('unterricht/incDate');
      this.getUnterricht();
      this.selectedDate = this.date.toDate();
    },
    decDate: function () {
      store.commit('unterricht/decDate');
      this.getUnterricht();
      this.selectedDate = this.date.toDate();
    },
    goToDate() {
      if (this.selectedDate) {
        let selectedDateformatted = moment(this.selectedDate).format('YYYY-MM-DD');
        store.commit('unterricht/customDate', selectedDateformatted);
        this.getUnterricht();
      }
    },
    formatDateGerman: function (dateString) {
      return moment(dateString).format('DD.MM.YYYY');
    },
    setDateLimits() {
      const lowerLimit = moment().subtract(2, 'years');
      this.lowerLimit = lowerLimit.toDate();
      const upperLimit = moment().add(2, 'years');
      this.upperLimit = upperLimit.toDate();
    },
    resetSelectedDate() {
      const today = moment().toDate();
      this.selectedDate = today;
      this.goToDate();
    },
    today() {
      return moment().isSame(this.selectedDate, 'day');
    }
  },
  computed: {
    date: function () {
      return store.state.unterricht.displayDate;
    },
    unterrichtMatrix: function () {
      return this.unterrichtMatrixMap[this.date.format('YYYY-MM-DD')];
    },
    columns: function () {
      return this.unterrichtMatrix ? Object.keys(this.unterrichtMatrix) : [];
    },
    loading: function () {
      return this.loadingMap[this.date.format('YYYY-MM-DD')];
    },
    weekdayName: function () {
      return store.getters['timetable/getWeekdayName'](this.date);
    },
    hours: function () {
      let min = 1;
      let max = 1;
      for (let key in this.unterrichtMatrix) {
        for (let entry of this.unterrichtMatrix[key]) {
          min = Math.min(min, entry.hour_from);
          max = Math.max(max, entry.hour_to);
        }
      }
      return Array.from(Array(max - min + 1).keys(), (value) => value + min);
    }
  },
  created() {
    this.getUnterricht();
  },
  mounted() {
    this.setDateLimits();
    this.selectedDate = this.date.toDate();
  },
}
</script>

<style scoped>
.table-cell {
  border-left: 1px solid rgba(34, 36, 38, .15)
}

.weekday-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
