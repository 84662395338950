<template>
  <banner text="Termine"/>

  <loader-local :active="loadingEvents"/>

  <calendar :events="events"/>
</template>

<script>
import Banner from "../components/Banner";
import LoaderLocal from "../components/LoaderLocal";
import Calendar from "../components/Calendar"
import store from '../store';

export default {
  components: {Banner, LoaderLocal, Calendar},
  data: function () {
    return {
      loadingEvents: false,
      events: store.getters['timetable/getEvents']
    }
  },
  methods: {
    checkEventsLoading: function () {
      this.loadingEvents = true;

      const {calendarEvents} = this.events;
      calendarEvents.subscribe((calendarEvents) => {
        if (calendarEvents !== null) {
          this.loadingEvents = false;
        }
      }, (error) => {
        // 401 redirects to login anyway
        if (error.response.status !== 401) {
          alert('Fehler beim Laden der Termine.');
          this.loadingEvents = false;
        }
      });
    }
  },
  created() {
    this.checkEventsLoading();
  }
}
</script>
