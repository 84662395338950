/**
 *
 * This function converts a custom markup language to HTML, rendering only specific tags.
 * It e.g. provides Users a method to format text according to their preferred styles.
 *
 * Syntax of the currently supported tags:
 *  - //italic//
 *  - **bold**
 *  - __underlined__
 *
 * The function uses regular expressions to search for these patterns and replace them with the corresponding HTML tags.
 *
 *
 */

export function formatText(text) {
  while (/\/\/([^/]+?)\/\/(?!\/)/g.test(text)) {
    text = text.replace(/\/\/([^/]+?)\/\/(?!\/)/g, '<i>$1</i>');
  }
  while (/\*\*([^*]+?)\*\*(?!\*)/g.test(text)) {
    text = text.replace(/\*\*([^*]+?)\*\*(?!\*)/g, '<b>$1</b>');
  }
  while (/__([^_]+?)__(?!\*)/g.test(text)) {
    text = text.replace(/__([^_]+?)__(?!\*)/g, '<u>$1</u>');
  }
  return text;
}
