import axios from 'axios';
import store from "../index"
import {BehaviorSubject} from "rxjs";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        unterrichtMap: {},
        displayDate: moment()
    },
    getters: {
        getUnterricht: (state) => {
            // Subscribe to data updates for unterricht on first fetch
            if (Object.keys(state.unterrichtMap).length === 0) {
                store.commit('unterricht/subscribeToDataUpdates');
            }

            const dateString = state.displayDate.format('YYYY-MM-DD');

            if (!state.unterrichtMap[dateString]) {
                state.unterrichtMap[dateString] = new BehaviorSubject(null);
            }
            if (state.unterrichtMap[dateString].getValue() === null) {
                store.dispatch('unterricht/fetchUnterricht', state.displayDate);
            }

            return state.unterrichtMap[dateString];
        }
    },
    mutations: {
        incDate(state) {
            state.displayDate = store.getters['timetable/getNextWeekday'](state.displayDate);
        },
        decDate(state) {
            state.displayDate = store.getters['timetable/getPrevWeekday'](state.displayDate);
        },
        customDate(state, date) {
            state.displayDate = moment(date);
        },
        refreshMap(state) {
            const dateString = state.displayDate.format('YYYY-MM-DD');

            // Delete all fetched data except for the current (resp. latest displayed) date
            Object.keys(state.unterrichtMap).forEach((item) => {
                if (dateString !== item) {
                    delete state.unterrichtMap[item];
                }
            });
        },
        setUnterricht(state, {date, value}) {
            state.unterrichtMap[date.format('YYYY-MM-DD')].next(value);
        },
        subscribeToDataUpdates() {
            store.commit('dataUpdates/subscribe', {
                dataType: 'unterricht',
                callback: () => store.dispatch('unterricht/refresh')
            });
        }
    },
    actions: {
        refresh({commit, dispatch}) {
            commit('refreshMap');
            return dispatch('fetchUnterricht');
        },
        fetchUnterricht({state, commit, rootGetters}) {
            const dateString = state.displayDate.format('YYYY-MM-DD');

            return axios.get(rootGetters.getUrl('/api/unterricht/' + dateString))
                .then(res => res.data)
                .then(contents => {
                    commit('setUnterricht', {
                        date: state.displayDate,
                        value: contents
                    })
                })
                .catch(error => state.unterrichtMap[dateString].error(error));
        }
    },
    modules: {}
}
