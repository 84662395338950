<template>
  <div id="top-bar" class="ui blue inverted segment">
    <div class="text">
      <div>
        <chat-icon :chat="chat"/>

        <b>{{ chat ? chat.name : 'Lädt...' }}</b>
      </div>
      <small v-if="chat">
        <u>{{ chat.owner.name }}</u>
        <span v-if="chatMembersString">, {{ chatMembersString }}</span>
      </small>
    </div>

    <div v-if="chat && chat.userIsAdmin">
      <i class="edit icon pointer" @click="toggleEditChatModal"/>

      <sui-modal v-model="editChatModalOpen">
        <sui-modal-header>Chat bearbeiten</sui-modal-header>
        <sui-modal-content v-if="editedChat">
          <form class="ui form" v-on:submit.prevent="updateChat">
            <div v-bind:class="[updatingChat ? 'ui loading search' : 'ui search']"
                 class="flex-grow-1 mb-1">
              <div class="ui fluid icon input">
                <input type="text" placeholder="mein Chat..." v-model="editedChat.name">
                <i class="comment outline icon"></i>
              </div>
            </div>

            <div class="ui toggle checkbox">
              <input type="checkbox" name="broadcast" v-model="editedChat.broadcast">
              <label>
                Broadcast <br>
                <small>Nur Eigentümer kann Nachrichten verschicken</small>
              </label>
            </div>

            <sui-accordion fluid styled class="mt-1 max-h-30 overflow-y-scroll">
              <sui-accordion-tab :title="'Mitglieder ('+chat.members.length+')'">
                <div class="ui small divided list">
                  <div class="item" v-for="member of chat.members" v-bind:key="member.id">
                    <div class="right floated">
                      <template v-if="member.canBeAdmin">
                        <div class="ui active inline mini loader" v-if="togglingAdmin && togglingAdmin[member.id]"/>
                        <template v-else>
                          <i class="user circle icon pointer"
                             title="Adminstatus entfernen"
                             @click="toggleAdmin(member)" v-if="member.isAdmin"/>
                          <i class="user circle outline icon pointer"
                             title="Adminstatus geben"
                             @click="toggleAdmin(member)" v-else/>
                        </template>
                      </template>
                      <div class="ui active inline mini loader" v-if="removingMember && removingMember[member.id]"/>
                      <i class="red trash icon pointer ml-05" title="Mitglied entfernen"
                         @click="removeMember(member.id)" v-else/>
                    </div>

                    <i class="user icon" v-if="member.type === 'App\\Models\\User'"/>
                    <i class="users icon" v-if="member.type === 'App\\Models\\UserGroup'"/>
                    <div class="content">
                      {{ member.name }}
                      <small class="color-gray" v-if="member.info">
                        <br>{{ member.info }}
                      </small>
                    </div>
                  </div>
                </div>
              </sui-accordion-tab>
            </sui-accordion>

            <div id="member-search">
              <div class="flex-grow-1 mr-1">
                <sui-dropdown search selection fluid id="member-search-dropdown"
                              v-model="memberToAdd"
                              :options="memberSearchOptions"
                              placeholder="Mitglied hinzufügen"/>
              </div>
              <div>
                <div class="ui active inline mini loader" v-if="gettingMembersToAdd || addingMember"/>
                <i class="blue user plus icon pointer" title="Mitglied hinzufügen"
                   @click="addMember" v-else/>
              </div>
            </div>
          </form>

          <p class="mt-1">
            <small>
              <i>Chat erstellt von {{ chat.owner.name }} {{ formatTimestamp(chat.createdAt) }}</i>
            </small>
          </p>
        </sui-modal-content>
        <sui-modal-actions>
          <sui-button class="left floated middle aligned" negative @click="deleteChat">
            <i class="trash icon m-0"/>
          </sui-button>
          <sui-button type="button" @click="toggleEditChatModal">
            abbrechen
          </sui-button>
          <sui-button type="button" positive @click="updateChat">
            speichern
          </sui-button>
        </sui-modal-actions>
      </sui-modal>
    </div>

    <div v-else-if="chat && !chat.userIsAdmin">
      <i class="eye icon pointer" @click="toggleViewChatModal"/>

      <sui-modal v-model="viewChatModalOpen">
        <sui-modal-header>
          <chat-icon :chat="chat"/>

          {{ chat.name }}
        </sui-modal-header>
        <sui-modal-content>
          <sui-accordion fluid styled>
            <sui-accordion-tab title="Mitglieder">
              <div class="ui small divided list">
                <div class="item" v-for="member of chat.members" v-bind:key="member.id">
                  <i class="user icon" v-if="member.type === 'App\\Models\\User'"/>
                  <i class="users icon" v-if="member.type === 'App\\Models\\UserGroup'"/>
                  <div class="content">
                    {{ member.name }}
                    <small class="color-gray" v-if="member.info">
                      <br>{{ member.info }}
                    </small>
                  </div>
                </div>
              </div>
            </sui-accordion-tab>
          </sui-accordion>

          <p class="mt-1">
            <small>
              <i>Chat erstellt von {{ chat.owner.name }} {{ formatTimestamp(chat.createdAt) }}</i>
            </small>
          </p>
        </sui-modal-content>
        <sui-modal-actions>
          <sui-button type="button" @click="toggleViewChatModal">
            schließen
          </sui-button>
        </sui-modal-actions>
      </sui-modal>
    </div>
  </div>

  <div id="chat-messages">
    <loader-local :active="loading"/>

    <div class="ui left aligned grid" v-if="chat">
      <div class="row">
        <div class="column center aligned">
          <div class="ui label">
            <span v-if="chatIsRequest">
              Chat automatisch durch Kontaktanfrage erstellt
            </span>
            <span v-else>
              {{ chat.owner.name }} hat den Chat erstellt
            </span>
            <div class="subline">
              {{ formatTimestamp(chat.createdAt) }}
            </div>
          </div>
        </div>
      </div>

      <template v-for="(message, index) of chat.messages" :key="message.id">

        <div v-if="(chat.messages[index-1] && chat.messages[index-1].read && !message.read)"
             class="sixteen wide column ui divider mb-0"></div>

        <div
            v-bind:class="[isUser(message.editor.id) ? 'right floated fourteen wide column' : 'left floated fourteen wide column']">
          <div v-bind:class="[isUser(message.editor.id) ? 'ui fluid raised green card' : 'ui fluid raised card']">
            <div class="content">
              <div class="description">

                <div class="d-flex justify-between">
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-direction-column">
                      <p class="white-space-pre-wrap" v-if="message.text && !message.isDeleted"
                         v-html="sanitizeMessage(formatMessage(message.text))"></p>
                      <p v-if="message.isDeleted" class="color-dark">
                        <i><i class="dont icon"></i>Diese Nachricht wurde gelöscht</i>
                      </p>
                      <template v-if="message.file && Object.keys(message.file).length">
                        <attachment-list :files="[message.file]"/>
                      </template>
                    </div>
                  </div>
                  <div v-if="!message.isDeleted">
                    <sui-menu-item class="chat-message-edit-dropdown">
                      <sui-dropdown icon="ellipsis vertical icon grey" pointing="top right" v-if="message.isDeletable"
                                    @click="this.selectedChatMessage = message.id;" class="chat-message-edit-dropdown">
                        <sui-dropdown-menu class="chat-message-edit-dropdown">
                          <sui-dropdown-item icon="trash icon grey" class="chat-message-edit-dropdown"
                                             text="Nachricht löschen"
                                             title="Ausgewählte Chatnachricht für alle User löschen"
                                             @click="deleteChatMessage();"/>
                        </sui-dropdown-menu>
                      </sui-dropdown>
                    </sui-menu-item>
                  </div>
                </div>

              </div>
            </div>
            <div class="extra content">
              <span class="left floated author" v-if="!isUser(message.editor.id)">
                {{ message.editor.name }}
                <template v-if="message.editor.isDeleted">
                  <br>
                  <small>(User gelöscht)</small>
                </template>
              </span>
              <span class="right floated author">
                {{ formatTimestamp(message.createdAt) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <div id="typing-infos" ref="typing-info">
    <div class="ui left aligned grid" v-if="chat">
      <div class="row" v-for="(typer, key) in activeTypers" v-bind:key="key">
        <div class="column center aligned">
          <div class="chat-bubble">
            <div class="typing">
              <span class="text">
                {{ typer }} tippt&nbsp;&nbsp;&nbsp;
              </span>
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form id="new-message-form" class="ui form" @submit.prevent="postMessage" v-if="userCanPostMessage()">
    <sui-label class="d-flex mb-05" v-if="fileAttachment" title="Anhang">
      <div class="pointer" @click="fileAttachment = null">
        <i class="times circle outline icon" title="Anhang entfernen"/>
      </div>
      <div class="flex-grow-1">
        <i class="paperclip icon"/>
        {{ fileAttachment.name }}
      </div>
    </sui-label>

    <div class="d-flex align-items-end">
      <button type="button" class="ui circular icon button message-button" @click="onPickFileAttachment">
        <i class="paperclip icon" title="Datei hinzufügen"/>
        <input type="file" class="d-none" ref="fileAttachmentInput" @change="onFileAttachmentPicked"/>
      </button>
      <div v-bind:class="[sending ? 'ui loading search' : 'ui search']" class="flex-grow-1 mt-0 mb-0 mr-05 ml-05">

        <div id="formatting-box" v-if="displayFormattingBox" ref="formattingBox">
          <div id="selected-text-box" class="ui segment bg-white box-shadow-0, p-0">
            <div class="ui buttons">
              <button ref="bold" @click="modifySelectedText('bold')" type="button" class="ui button formatting-button"
                      title='Fett'><i class="bold icon"></i></button>
              <button ref="italic" @click="modifySelectedText('italic')" type="button"
                      class="ui button formatting-button" title='Kursiv'><i class="italic icon"></i></button>
              <button ref="underlined" @click="modifySelectedText('underlined')" type="button"
                      class="ui button formatting-button" title='Unterstrichen'><i class="underline icon"></i></button>
            </div>
          </div>
        </div>

        <div class="ui fluid icon input" @mouseup="getSelectedText('selection')"
             @keydown.ctrl.a="getSelectedText('all')">
          <textarea id="chat-message-input" rows="1" v-model="newMessage"
                    @keyup.enter.ctrl.prevent="postMessage" ref="newMessage" type="text" :input="autoExpand()"
                    @select="displayFormattingBox = true" @keydown="displayFormattingBox = false"/>
          <i class="envelope outline icon" id="envelopeIcon"></i>
        </div>

      </div>
      <button class="ui blue icon button message-button">
        <i class="paper plane icon"/>
      </button>
    </div>
  </form>

</template>

<script>
import LoaderLocal from "../components/LoaderLocal";
import store from '../store';
import moment from "moment";
import AttachmentList from "../components/AttachmentList";
import linkifyHtml from 'linkify-html';
import DOMPurify from 'isomorphic-dompurify';
import ChatIcon from "../components/ChatIcon";
import {formatText} from '../formatting.js';

export default {
  components: {ChatIcon, LoaderLocal, AttachmentList},
  data: function () {
    return {
      loading: false,
      sending: false,
      chat: null,
      newMessage: '',
      fileAttachment: null,
      viewChatModalOpen: false,
      editChatModalOpen: false,
      editedChat: null,
      updatingChat: false,
      membersToAdd: [],
      memberSearchOptions: [],
      memberToAdd: null,
      gettingMembersToAdd: false,
      addingMember: false,
      removingMember: {},
      togglingAdmin: {},
      listeningForTyping: false,
      chatSubscription: null,
      typing: {},
      selectionStart: null,
      selectionEnd: null,
      selectedChatMessage: null,
      selectedText: '',
      displayFormattingBox: false
    }
  },
  methods: {
    getSelectedText(mode) {
      if (mode === "selection") {
        const textarea = this.$refs.newMessage;
        this.selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd);
        if (this.selectedText === this.selectedText) {
          this.displayFormattingBox = false;
        }
      } else if (mode === "all") {
        this.selectedText = this.newMessage;
        this.displayFormattingBox = false;
      }
    },
    modifySelectedText(format) {
      const formattingBox = this.$refs.formattingBox;
      if (formattingBox) {
        formattingBox.style.display = 'none';
      }
      const textarea = this.$refs.newMessage;
      let selText = this.selectedText;

      let start = textarea.selectionStart;
      let end = textarea.selectionEnd;
      let lastIndex = selText.lastIndexOf(" ");
      let trimWhitespace = false;

      if (lastIndex !== -1 && lastIndex === selText.length - 1) {
        selText = selText.trim();
        trimWhitespace = true;
      }

      let marker = '';
      switch (format) {
        case 'bold':
          marker = '**'
          break;
        case 'italic':
          marker = '//'
          break;
        case 'underlined':
          marker = '__'
          break;
      }

      let styledText = marker + selText + marker;
      if (trimWhitespace) {
        styledText = styledText + " ";
      }
      textarea.setRangeText(styledText, start, end, "end");
      this.newMessage = this.newMessage.substring(0, start) + styledText + this.newMessage.substring(end);
    },
    autoExpand() {
      if (!this.$refs.newMessage) return;

      const textarea = this.$refs.newMessage;
      const maxHeight = window.innerHeight - 500;
      textarea.style.maxHeight = maxHeight + 'px';
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
      if (textarea.scrollHeight >= maxHeight) {
        textarea.style.overflow = "scroll";
      } else {
        textarea.style.overflow = "hidden";
      }
    },
    getChat: function (chatId) {
      this.loading = true;
      this.chatSubscription = store.getters['chat/getChat'](chatId)
          .subscribe((chat) => {
            if (chat !== null) {
              this.chat = chat;
              this.sortMembers();
              this.loading = false;
              this.scrollBottom();

              // Open edit modal if chat was lately created
              if (this.$route.query.created) {
                if (this.chat.userIsAdmin) {
                  this.toggleEditChatModal(true)
                }
                // Remove query param
                this.$router.replace({'query': null});
              }

              this.listenForTyping();
            }
          }, (error) => {
            // 401 redirects to login anyway
            if (error.response.status !== 401) {
              alert('Fehler beim Laden des Chats.');
              this.loading = false;
            }
          });
    },
    sanitizeMessage: function (message) {
      return DOMPurify.sanitize(
          linkifyHtml(message, {
            target: '_blank'
          }), {
            ALLOWED_TAGS: ['a', 'b', 'i', 'u'],
            ALLOWED_ATTR: ['href', 'target']
          });
    },
    formatMessage: function (message) {
      return formatText(message ? message.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;') : null);
    },
    sortMembers: function () {
      this.chat.members.sort((a, b) => {
        if (a.type < b.type) {
          return 1;
        } else if (a.type > b.type) {
          return -1;
        } else if (a.type === b.type) {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          } else {
            return 1;
          }
        }
      });
    },
    onPickFileAttachment() {
      this.$refs.fileAttachmentInput.click();
    },
    onFileAttachmentPicked(event) {
      this.fileAttachment = event.target.files[0];
    },
    userCanPostMessage: function () {
      if (!this.chat) {
        return false;
      }
      // On broadcast only the admins can post messages
      return !this.chat.broadcast
          || this.chat.userIsAdmin
    },
    postMessage: function () {
      if (!this.newMessage && !this.fileAttachment) return;

      let formData = new FormData();

      if (this.newMessage) {
        formData.append('text', this.newMessage);
      }
      if (this.fileAttachment) {
        formData.append('file', this.fileAttachment);
      }

      this.sending = true;
      store.dispatch('chat/postMessage', {chatId: this.chat.id, formData: formData})
          .then(() => {
            this.newMessage = '';
            this.fileAttachment = null;
            this.sending = false;
            this.$refs.newMessage.style.height = 'auto';
          })
          .catch((error) => {
            // 401 redirects to login and validation error is alerted anyway
            if (![401, 422].includes(error.response.status)) {
              alert('Fehler beim Speichern der Nachricht.');
            }
            this.sending = false;
          });
    },
    updateChat: function () {
      this.updatingChat = true;
      store.dispatch('chat/updateChat', this.editedChat)
          .then(() => {
            this.toggleEditChatModal();
            this.updatingChat = false;
          });
      // Add member if one is selected but not added by clicking the 'user plus' button
      this.addMember();
    },
    getMembersToAdd: function () {
      this.gettingMembersToAdd = true;
      store.dispatch('chat/getAllPotentialMembers', this.chat.id)
          .then((members) => {
            this.membersToAdd = members;
            this.memberSearchOptions = this.membersToAdd.map((member) => {
              var text = member.name;
              if (member.info) {
                text += ' (' + member.info + ')';
              }
              return {
                text: text,
                value: member
              }
            })
            this.gettingMembersToAdd = false;
          })
          .catch(() => {
            this.gettingMembersToAdd = false;
          });
    },
    addMember: function () {
      if (!this.memberToAdd) return;

      this.addingMember = true;
      store.dispatch('chat/addMember', {chatId: this.chat.id, member: this.memberToAdd})
          .then((res) => {
            // if new member added
            if (res.status === 201) {
              this.chat.members.push(res.data);
              this.sortMembers();
            }
            this.memberToAdd = null;
            this.addingMember = false;
          })
          .catch(() => this.addingMember = false);
    },
    removeMember: function (memberId) {
      if (!confirm('Möchten Sie dieses Mitglied wirklich aus dem Chat entfernen?')) return;

      this.removingMember[memberId] = true;
      store.dispatch('chat/removeMember', memberId)
          .then(() => {
            this.removingMember[memberId] = false;
            // remove member from chat members list
            this.chat.members = this.chat.members.filter((member) => {
              return member.id !== memberId;
            })
          });
    },
    toggleAdmin: function (member) {
      if (!confirm('Möchten Sie diesem Mitglied den Adminstatus wirklich ' + (member.isAdmin ? 'entziehen' : 'geben') + '?')) return;

      this.togglingAdmin[member.id] = true;
      store.dispatch('chat/toggleAdmin', member.id)
          .then(() => {
            this.togglingAdmin[member.id] = false;
            // set new admin status for member
            member.isAdmin = !member.isAdmin;
          });
    },
    deleteChat: function () {
      if (!confirm('Möchten Sie diesen Chat wirklich für alle löschen?')) return;

      this.toggleEditChatModal();
      store.dispatch('chat/deleteChat', this.chat);
    },
    deleteChatMessage: function () {
      store.dispatch('chat/deleteChatMessage', {chatId: this.chat.id, messageId: this.selectedChatMessage});
    },
    toggleViewChatModal: function () {
      this.viewChatModalOpen = !this.viewChatModalOpen;
    },
    toggleEditChatModal: function (state = null) {
      this.editedChat = {...this.chat};

      if (this.membersToAdd.length === 0) {
        this.getMembersToAdd();
      }

      if (typeof state === "boolean") {
        this.editChatModalOpen = state;
      } else {
        this.editChatModalOpen = !this.editChatModalOpen;
      }
    },
    isUser: function (userId) {
      return store.getters['auth/isAuthUser'](userId);
    },
    formatTimestamp: function (timestamp) {
      return moment(timestamp, 'X').calendar();
    },
    listenForTyping: function () {
      if (this.listeningForTyping) return;

      store.getters['websocket/Echo']
          .private(`chat.` + this.chat.id)
          .listenForWhisper('typing', (e) => {
            if (!this.typing[e.id]) {
              this.typing[e.id] = {};
            }
            this.typing[e.id]['name'] = e.name;
            this.typing[e.id]['count'] = this.typing[e.id]['count'] ? (this.typing[e.id]['count'] + 1) : 1;

            this.scrollBottom();

            setTimeout(() => {
              this.typing[e.id]['count']--;
            }, 5000);
          })
          .subscribed(() => {
            this.listeningForTyping = true;
          });
    },
    broadcastTyping: function () {
      store.getters['websocket/Echo']
          .private(`chat.` + this.chat.id)
          .whisper('typing', {
            id: store.state.auth.user.id,
            name: store.state.auth.user.name
          });
    },
    scrollBottom: function () {
      setTimeout(() => {
        if (this.$refs['typing-info']) {
          this.$refs['typing-info'].scrollIntoView({behavior: "smooth"});
        }
      }, 200);
    }
  },
  watch: {
    newMessage(newText) {
      if (newText)
        this.broadcastTyping();
    }
  },
  computed: {
    chatIsRequest: function () {
      return this.chat.name.startsWith('Anfrage von')
    },
    chatMembersString: function () {
      return this.chat.members.map((mem) => {
        return mem.name
      }).join(', ');
    },
    activeTypers: function () {
      const typers = [];
      for (let typer of Object.values(this.typing)) {
        if (typer.count > 0) {
          typers.push(typer.name);
        }
      }
      return typers;
    },
  },
  created() {
    this.getChat(this.$route.params.chatId);
  },
  beforeUnmount() {
    this.chatSubscription.unsubscribe();
    store.dispatch('chat/leaveChat', this.chat.id);
  }
}
</script>

<style scoped>
#top-bar {
  position: fixed;
  top: 3rem;
  z-index: 10;
  width: calc(100% - 2rem);
  max-width: calc(768px - 2rem);
  text-align: left;
  display: flex;
  justify-content: space-between;
}

#top-bar .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#chat-messages {
  margin-top: 6rem;
  margin-bottom: 1rem;
}

#typing-infos {
  padding-bottom: 7rem;
}

#typing-infos .row {
  padding-bottom: 0;
}

#new-message-form {
  position: fixed;
  bottom: 5rem;
  width: calc(100% - 2rem);
  max-width: calc(768px - 2rem);
}

#chat-message-input {
  height: 40px;
  resize: none;
  overflow: hidden;
}

#member-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

#formatting-box {
  position: absolute;
  top: -42px;
}

.ui.grid > .column:not(.row) {
  padding-bottom: 0 !important;
}

.ui.card > .extra.content {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
}

.ui.label > .subline {
  font-weight: normal;
}

.chat-bubble {
  background-color: #E6F8F1;
  padding: 8px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .text {
  color: #42b983;
}

.typing .dot {
  animation: typingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

.formatting-button {
  width: 50px;
}

.description {
  overflow-wrap: anywhere;
}

@keyframes typingAnimation {
  0% {
    transform: translateY(3px);
    background-color: #6CAD96;
  }
  28% {
    transform: translateY(-4px);
    background-color: #9ECAB9;
  }
  44% {
    transform: translateY(3px);
    background-color: #B5D9CB;
  }
}

.chat-message-edit-dropdown .transition {
  animation-duration: .05s;
}

.chat-message-edit-dropdown .ui.pointing.upward.dropdown .menu, .ui.top.pointing.upward.dropdown .menu {
  left: -146px !important;
}

.chat-message-edit-dropdown .ui.top.right.pointing.dropdown > .menu {
  left: -146px !important;
}

.message-button {
  height: 40px;
  width: 40px;
}

</style>
