<template>
  <sui-menu size="mini" class="fixed" :class="{ 'blue inverted': adminModeIsActive }">
    <sui-menu-item>
      <svg id="svg" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0, 0, 400,254.35897435897436">
        <g id="svgg">
          <path id="path0"
            d="M0.000 199.873 L 0.000 254.453 54.580 254.453 L 109.160 254.453 109.160 199.873 L 109.160 145.293 54.580 145.293 L 0.000 145.293 0.000 199.873 "
            stroke="none" fill="#94941c" fill-rule="evenodd"></path>
          <path id="path1"
            d="M290.840 54.580 L 290.840 109.160 345.420 109.160 L 400.000 109.160 400.000 54.580 L 400.000 0.000 345.420 0.000 L 290.840 0.000 290.840 54.580 "
            stroke="none" fill="#dc1c2c" fill-rule="evenodd"></path>
          <path id="path2"
            d="M290.840 199.873 L 290.840 254.453 345.420 254.453 L 400.000 254.453 400.000 199.873 L 400.000 145.293 345.420 145.293 L 290.840 145.293 290.840 199.873 "
            stroke="none" fill="#9474b4" fill-rule="evenodd"></path>
          <path id="path3"
            d="M0.000 54.580 L 0.000 109.160 54.580 109.160 L 109.160 109.160 109.160 54.580 L 109.160 0.000 54.580 0.000 L 0.000 0.000 0.000 54.580 "
            stroke="none" fill="#2c5ca4" fill-rule="evenodd"></path>
          <path id="path4"
            d="M145.208 145.462 C 145.115 145.555,145.038 170.117,145.038 200.042 L 145.038 254.453 200.001 254.453 L 254.963 254.453 254.899 199.936 L 254.835 145.420 200.107 145.356 C 170.006 145.320,145.302 145.368,145.208 145.462 "
            stroke="none" fill="#fcb424" fill-rule="evenodd"></path>
          <path id="path5"
            d="M145.038 54.580 L 145.038 109.160 200.000 109.160 L 254.962 109.160 254.962 54.580 L 254.962 0.000 200.000 0.000 L 145.038 0.000 145.038 54.580 "
            stroke="none" fill="#e45414" fill-rule="evenodd"></path>
        </g>
      </svg>
    </sui-menu-item>

    <router-link v-if="schoolInfo" is="sui-menu-item" to="/about" class="item" active-class="active">
      {{ schoolInfo.address1 }}
    </router-link>

    <sui-menu-item position="right" v-if="auth">
      <sui-dropdown icon="user" pointing="top right">
        {{ user.name }}
        <sui-dropdown-menu>
          <sui-dropdown-item text="Profil" v-on:click="goToProfile" v-if="!adminModeIsActive" />
          <sui-dropdown-item text="Benachrichtigungen" v-on:click="goToNotifications" v-if="!adminModeIsActive" />
          <a :href="helpLink" target="_blank">
            <sui-dropdown-item text="Hilfe" class="textColorBlack" />
          </a>
          <sui-dropdown-item text="Logout" v-on:click="logout" />
        </sui-dropdown-menu>
      </sui-dropdown>
    </sui-menu-item>
    <sui-menu-item position="right" v-else @click="goToLogin">
      Login
    </sui-menu-item>
  </sui-menu>
</template>

<script>
import store from "../store";

export default {
  name: "TopMenu",
  props: ['user', 'auth'],
  methods: {
    logout: function () {
      store.dispatch('auth/logout');
    },
    goToLogin: function () {
      this.$router.push('/login');
    },
    goToProfile: function () {
      this.$router.push('/profile');
    },
    goToNotifications: function () {
      this.$router.push('/notifications');
    }
  },
  computed: {
    schoolInfo: function () {
      return store.state.auth.schoolInfo;
    },
    helpLink: function () {
      return store.getters['config/getHelpLink'];
    },
    adminModeIsActive: function () {
      return store.getters['auth/adminModeIsActive'];
    }
  }
}
</script>

<style>
.ui.inverted.menu .item {
  color: rgba(0, 0, 0, .87) !important;
}
</style>