<template>

  <banner text="Kontaktanfrage"/>

  <sui-segment>
    <h5>Neue Kontaktanfrage stellen</h5>

    <sui-dropdown search selection
                  v-model="target"
                  :options="targetSearchOptions"
                  placeholder="Wer soll kontaktiert werden"/>

    <div class="mt-1">
      <sui-button type="button" positive @click="sendRequest" :disabled="adminModeIsActive ? true : null">
        abschicken
      </sui-button>
    </div>
  </sui-segment>

</template>

<script>
import Banner from "../components/Banner";
import store from "../store";

export default {
  components: {Banner},
  data: function () {
    return {
      targetSearchOptions: [],
      target: null,
    }
  },
  methods: {
    sendRequest: function () {
      if (this.target && confirm('Möchtest du die Anfrage an ' + this.target.value.name + ' abschicken?')) {
        store.dispatch('chatRequest/sendRequest', this.target.value.user_id)
            .then((res) => {
              if (res)
                this.$router.push('/chat/' + res.data.id);
            });
      }
    },
    getTargets: function () {
      store.dispatch('chatRequest/getTargets')
          .then((targets) => {
            this.targetSearchOptions = targets.map((target) => {
              let text = target.name;
              if (target.info) {
                text += ' (' + target.info + ')';
              }
              return {
                text: text,
                value: target
              }
            })
          });
    }
    ,
  },
  computed: {
    adminModeIsActive: function () {
      return store.getters['auth/adminModeIsActive'];
    }
  },
  created() {
    this.getTargets();
  }
}
</script>
