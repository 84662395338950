<template>
  <h2>Profil</h2>

  <div class="ui segment">

    <h4 v-if="userHasPassword">Passwort ändern</h4>
    <h4 v-else>Passwort setzen</h4>

    <form class="ui form error success" v-on:submit.prevent="updatePassword">

      <div v-bind:class="[oldPasswordError ? 'field error' : 'field']" v-if="userHasPassword">
        <label>Altes Passwort</label>
        <input type="password" required v-model="updatePasswordForm.old_password" placeholder="secret*123"/>
      </div>
      <div class="ui error message" v-if="oldPasswordError">
        <p>Das alte Passwort ist nicht korrekt.</p>
      </div>

      <div v-bind:class="[passwordError ? 'field error' : 'field']">
        <label>Neues Passwort</label>
        <input type="password" required v-model="updatePasswordForm.password" placeholder="secret*123"/>
      </div>

      <div v-bind:class="[passwordError ? 'field error' : 'field']">
        <label>Neues Passwort bestätigen</label>
        <input type="password" required v-model="updatePasswordForm.password_confirmation" placeholder="secret*123"/>
      </div>
      <div class="ui error message" v-if="passwordError">
        <p>{{ passwordError }}</p>
      </div>

      <button type="submit" class="ui green button">neues Passwort speichern</button>

      <div class="ui success message" v-if="updatePasswordSuccess">
        Das Passwort wurde aktualisiert.
      </div>

    </form>

  </div>

  <div class="ui segment">

    <h4>Email Adresse ändern</h4>

    <form class="ui form error success" v-on:submit.prevent="updateEmail">

      <div class="field">
        <label>aktuell</label>
        {{ userEmail }}
      </div>
      <div v-bind:class="[emailError ? 'field error' : 'field']">
        <label>neu</label>
        <input type="email" required v-model="updateEmailForm.email" placeholder="maxmuster@mail.de"/>
      </div>

      <div class="ui error message" v-if="emailError">
        <p>{{ emailError }}</p>
      </div>

      <button type="submit" class="ui green button">neue Email speichern</button>
    </form>

    <div class="ui success message" v-if="verifyingUpdatedEmailSuccess">
      Deine neue Email-Adresse wurde erfolgreich verifiziert und ist ab sofort gültig.
    </div>

    <sui-modal v-model="updateEmailSuccess">
      <sui-modal-header>Email Adresse verifizieren</sui-modal-header>
      <sui-modal-content>
        <p>
          Wir haben dir eine Email mit einem Code zum Bestätigen der neuen Adresse geschickt.
        </p>
        <p>Bitte gib diesen Code hier ein.</p>

        <form class="ui error form" v-on:submit.prevent="verifyUpdatedEmail">
          <div v-bind:class="[verifyingUpdatedEmailError ? 'field error' : 'field']">
            <input type="text" v-model="verifyUpdatedEmailForm.code" required>
          </div>

          <div class="ui error message" v-if="verifyingUpdatedEmailError">
            <p>Der Code war leider nicht korrekt. Bitte versuche er erneut!</p>
            <p>Du kannst dir auch einen neuen Code schicken lassen, indem du erneut auf Email ändern klickst.</p>
          </div>
        </form>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" @click="updateEmailSuccess = false">
          abbrechen
        </sui-button>
        <sui-button type="button" positive @click="verifyUpdatedEmail"
                    v-bind:class="[verifyingUpdatedEmail ? 'loading' : '']">
          verifizieren
        </sui-button>
      </sui-modal-actions>
    </sui-modal>

  </div>

</template>

<script>
import store from "../store";

export default {
  data: function () {
    return {
      updatePasswordForm: {
        old_password: null,
        password: null,
        password_confirmation: null,
      },
      updateEmailForm: {
        email: null
      },
      errors: null,
      updatePasswordSuccess: false,
      updateEmailSuccess: false,
      verifyUpdatedEmailForm: {
        code: null
      },
      verifyingUpdatedEmail: false,
      verifyingUpdatedEmailSuccess: false,
      verifyingUpdatedEmailError: false
    }
  },
  methods: {
    updatePassword: function () {
      store.dispatch('auth/updatePassword', this.updatePasswordForm)
          .then(() => {
            this.errors = null;
            this.updatePasswordSuccess = true;
            setTimeout(() => this.updatePasswordSuccess = false, 5000);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.errors = {oldPassword: true}
            } else {
              this.errors = error.response.data.errors;
            }
          });
    },
    updateEmail: function () {
      this.verifyingUpdatedEmailSuccess = false;
      this.verifyingUpdatedEmailError = false;
      this.verifyUpdatedEmailForm.code = null;

      store.dispatch('auth/updateEmail', this.updateEmailForm)
          .then(() => {
            this.errors = null;
            this.updateEmailSuccess = true;
          })
          .catch((error) => {
            this.errors = error.response.data.errors;

            if (!this.emailError) {
              alert('Es ist ein Fehler aufgetreten. Bitte versuche es erneut.')
            }
          });
    },
    verifyUpdatedEmail: function () {
      if (!this.verifyUpdatedEmailForm.code) return;

      this.verifyingUpdatedEmail = true;
      store.dispatch('auth/verifyUpdatedEmail', this.verifyUpdatedEmailForm)
          .then(() => {
            this.updateEmailSuccess = false;
            this.verifyingUpdatedEmailSuccess = true;
            this.verifyUpdatedEmailForm.code = null;
            this.updateEmailForm.email = null;
            setTimeout(() => this.verifyingUpdatedEmailSuccess = false, 5000);
          })
          .catch(() => {
            this.verifyingUpdatedEmailError = true;
          })
          .finally(() => this.verifyingUpdatedEmail = false);
    }
  },
  computed: {
    userEmail: function () {
      return store.state.auth.user.email;
    },
    userHasPassword: function () {
      return store.state.auth.user.hasPassword;
    },
    oldPasswordError: function () {
      return this.errors && this.errors.oldPassword;
    },
    passwordError: function () {
      if (!this.errors || !this.errors.password) {
        return null;
      }

      return this.errors.password[0];
    },
    emailError: function () {
      if (!this.errors || !this.errors.email) {
        return null;
      }

      return this.errors.email[0];
    }
  }
}
</script>
