<template>
  <img src="antago_cert_zs4art60i007680.png"/>
</template>

<style scoped>
img {
  width: 50%;
  max-width: 200px;
}
</style>
