import {Observable} from "rxjs";
import store from "../index";

export default {
    namespaced: true,
    state: {
        observer: null,
        relevantDataTypes: [
            undefined,
            'stundenplan',
            'vertretungsplan',
            'hausaufgaben',
            'news',
            'events',
            'unterricht'
        ]
    },
    getters: {},
    mutations: {
        subscribe(state, {dataType, callback}) {
            if (!state.relevantDataTypes.includes(dataType)) {
                console.log('Data type ' + dataType + ' is not available to subscribe for updates!');
                return;
            }

            return state.observer.subscribe((dataUpdateType) => {
                if (!dataType || dataUpdateType === dataType) {
                    callback();
                }
            })
        },
        initObserver(state) {
            state.observer = new Observable(subscriber => {
                store.getters['websocket/Echo']
                    .private('data-updates.' + store.state.auth.user.id)
                    .listen('DataUpdated', (data) => {
                        subscriber.next(data.dataType);
                    });
            })
        }
    },
    actions: {},
    modules: {}
}
