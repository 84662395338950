<template>
  <FullCalendar :options="calendarOptions"/>

  <div class="ui list text-left">
    <div class="item">
      <span class="ui empty circular mini label" v-bind:class="'bg-' + this.getColors().schulaufgaben"></span>
      große Leistungsnachweise
    </div>
    <div class="item">
      <span class="ui empty circular mini label" v-bind:class="'bg-' + this.getColors().exTemporalen"></span>
      kleine angekündigte Leistungsnachweise
    </div>
    <div class="item">
      <span class="ui empty circular mini label" v-bind:class="'bg-' + this.getColors().calendarEvents"></span>
      Kalendertermine
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import allLocales from '@fullcalendar/core/locales-all';

export default {
  name: 'Calendar',
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  props: [
    'events'
  ],
  data() {
    return {
      calendarOptions: {
        eventSources: [

          {
            name: 'calendarEvents',
            events: [],
            color: this.getColors().calendarEvents
          },
          {
            name: 'schulaufgaben',
            events: [],
            color: this.getColors().schulaufgaben
          },
          {
            name: 'exTemporalen',
            events: [],
            color: this.getColors().exTemporalen
          }

        ],
        plugins: [dayGridPlugin, interactionPlugin, listPlugin],
        initialView: 'dayGridDay',
        validRange: function (nowDate) {
          return {
            start: nowDate
          };
        },
        weekends: false,
        selectable: false,
        editable: false,
        navLinks: true,
        locale: 'de',
        locales: allLocales,
        height: 'auto',
        headerToolbar: {
          start: 'prev,today,next',
          center: 'title',
          end: 'dayGridDay,dayGridWeek,dayGridMonth,listMonth'
        },
        allDayText: 'ganztägig',
        weekText: 'KW',
        buttonText: {
          today: 'H',
          month: 'M',
          week: 'W',
          day: 'T',
          list: 'L'
        },
        buttonHints: {
          today: 'Heute',
          month: 'Monat',
          week: 'Woche',
          day: 'Tag',
          list: 'Liste',
          next: this.buildNextButtonHint,
          prev: this.buildPrevButtonHint
        }
      }
    }
  },
  methods: {
    updateCalenderEvents: function (calendarEvents) {
      this.updateEventSourceEvents('calendarEvents', calendarEvents ? calendarEvents.map(ev => this.formatCalendarEventToEvent(ev)) : null);
    },
    updateSchulaufgaben: function (schulaufgaben) {
      this.updateEventSourceEvents('schulaufgaben', schulaufgaben ? schulaufgaben.map(sa => this.formatLeistungsnachweisToEvent(sa)) : null);
    },
    updateExTemporalen: function (exTemporalen) {
      this.updateEventSourceEvents('exTemporalen', exTemporalen ? exTemporalen.map(ex => this.formatLeistungsnachweisToEvent(ex)) : null);
    },
    updateEventSourceEvents: function (eventSourceName, events) {
      const eventSources = JSON.parse(JSON.stringify(this.calendarOptions.eventSources));
      eventSources.find((source) => source.name === eventSourceName).events = events;

      this.calendarOptions.eventSources = eventSources;
    },
    formatLeistungsnachweisToEvent: function (leistungsnachweis) {
      let type = '';
      switch (leistungsnachweis.typ) {
        case 's':
          type = 'SA';
          break;
        case 'k':
          type = 'KA';
          break;
        default:
          type = 'KL';
      }

      return {
        title: leistungsnachweis.fach + ' ' + type + ' (' + leistungsnachweis.klasse + ')',
        start: leistungsnachweis.date,
        allDay: true
      }
    },
    formatCalendarEventToEvent: function (calendarEvent) {
      const event = JSON.parse(JSON.stringify(calendarEvent));
      event.allDay = calendarEvent.all_day;

      return event
    },
    getColors: function () {
      return {
        calendarEvents: 'green',
        schulaufgaben: 'red',
        exTemporalen: 'blue'
      }
    },
    buildNextButtonHint: function (buttonText, buttonName) {
      return (this.getButtonGender(buttonName) === 'm' ? 'Nächster' : 'Nächste') + ' ' + this.calendarOptions.buttonHints[buttonName];
    },
    buildPrevButtonHint: function (buttonText, buttonName) {
      return (this.getButtonGender(buttonName) === 'm' ? 'Vorheriger' : 'Vorherige') + ' ' + this.calendarOptions.buttonHints[buttonName];
    },
    getButtonGender: function (buttonName) {
      // Determine the gender in German locale
      switch (buttonName) {
        case 'week':
          return  'f';
      }
      return 'm';
    }
  },
  created() {
    const {calendarEvents, schulaufgaben, exTemporalen} = this.events;

    calendarEvents.subscribe((calendarEvents) => this.updateCalenderEvents(calendarEvents));

    schulaufgaben.subscribe((schulaufgaben) => this.updateSchulaufgaben(schulaufgaben));

    exTemporalen.subscribe((exTemporalen) => this.updateExTemporalen(exTemporalen));
  }
}
</script>

<style>
h2.fc-toolbar-title {
  font-size: 1em !important;
}
</style>