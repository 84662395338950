<template>
  <div class="ui list" v-if="files.length > 0">
    <div class="item" v-for="(file, key) of files" :key="key">
      <div class="content">
        <i class="paperclip icon"></i>
        <a :href="file.link">{{ file.name }}</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AttachmentList",
  props: ['files']
}
</script>
