<template>
  <sui-menu fluid :widths="1 + displayHausaufgaben + displayChats + displayStundenplan" icon="labeled" size="mini"
            class="bottom fixed"
            :class="{ 'blue inverted': adminModeIsActive }">
    <router-link is="sui-menu-item" to="/" class="item" active-class="active">
      <sui-icon name="home" size="big"/>
      Home
    </router-link>
    <router-link v-if="displayHausaufgaben" is="sui-menu-item" to="/hausaufgaben" class="item" active-class="active">
      <sui-icon name="pencil" size="big"/>
      Hausaufgaben
    </router-link>
    <router-link v-if="displayChats" is="sui-menu-item" to="/chat" class="item" active-class="active">
      <div class="relative">
        <sui-icon name="comments" size="big"/>
        <div class="floating ui mini red circular label" v-if="unreadMessagesCount > 0">
          {{ unreadMessagesCount }}
        </div>
      </div>
      Chats
    </router-link>
    <router-link v-if="displayStundenplan" is="sui-menu-item" to="/stundenplan" class="item" active-class="active">
      <sui-icon name="table" size="big"/>
      Stundenplan
    </router-link>
  </sui-menu>
</template>

<script>
import store from '../store';

export default {
  name: "BottomMenu",
  data: function () {
    return {
      unreadMessagesCount: 0,
    }
  },
  computed: {
    displayChats: function () {
      return !this.adminModeIsActive
          && this.chatsActive
    },
    displayHausaufgaben: function () {
      return this.authUserIsStudent
          && this.hausaufgabenActive
    },
    displayStundenplan: function () {
      return this.authUserIsStudent
          && this.stundenplanActive
    },
    authUserIsStudent: function () {
      return store.getters['auth/isStudent'];
    },
    adminModeIsActive: function () {
      return store.getters['auth/adminModeIsActive'];
    },
    chatsActive: function () {
      return store.getters['navigation/getSetup'].chats;
    },
    hausaufgabenActive: function () {
      return store.getters['navigation/getSetup'].unterricht.homework;
    },
    stundenplanActive: function () {
      return store.getters['navigation/getSetup'].timetable.stundenplan
          || store.getters['navigation/getSetup'].timetable.vertretungsplan;
    }
  },
  mounted() {
    if (this.displayChats) {
      store.getters['chat/getChats']
          .subscribe((chats) => {
            if (chats !== null) {
              this.unreadMessagesCount = chats.reduce(
                  (currentValue, chat) => {
                    return chat.unreadMessagesCount + currentValue
                  }, 0
              );

              if (this.unreadMessagesCount === 0) {
                store.dispatch('serviceWorker/clearBadge');
              } else {
                store.dispatch('serviceWorker/setBadge', this.unreadMessagesCount);
              }
            }
          })
    }
  }
}
</script>
