import axios from 'axios';

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        getTargets({commit, rootGetters}) {
            commit('setLoading', true, {root: true});

            return axios.get(rootGetters.getUrl('/api/chat-request/targets'))
                .then(res => res.data)
                .catch(() => alert('Fehler bei Laden der Ziele für eine Kontaktanfrage.'))
                .finally(() => commit('setLoading', false, {root: true}));
        },
        sendRequest({commit, rootGetters}, targetId) {
            commit('setLoading', true, {root: true});

            return axios.post(rootGetters.getUrl('/api/chat-request'), {
                target_id: targetId
            })
                .catch(err => {
                    if (err.response.status === 429) {
                        alert('Das Limit für Kontaktanfragen an diesen User pro Tag wurde erreicht.');
                    } else {
                        alert('Fehler beim Abschicken der Kontaktanfrage.');
                    }
                })
                .finally(() => commit('setLoading', false, {root: true}));
        }
    },
    modules: {}
}
