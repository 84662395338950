import axios from 'axios';
import moment from "moment";
import {BehaviorSubject} from "rxjs";
import store from "../index";

moment.locale(process.env.VUE_APP_LOCALE);

export default {
    namespaced: true,
    state: {
        calendarEvents: new BehaviorSubject(null),
        schulaufgaben: new BehaviorSubject(null),
        exTemporalen: new BehaviorSubject(null),
        stundenplan: new BehaviorSubject(null),
        zeittafel: new BehaviorSubject(null),
        vertretungsplan: new BehaviorSubject(null),
        vertretungsplanMitteilungen: new BehaviorSubject(null),
        weekdays: moment.weekdays().slice(1, 6)
    },
    getters: {
        getEvents: (state) => {
            if (state.calendarEvents.getValue() === null) {
                store.dispatch('timetable/loadEvents');
                store.commit('dataUpdates/subscribe', {
                    dataType: 'events',
                    callback: () => store.dispatch('timetable/loadEvents')
                })
            }
            return {
                calendarEvents: state.calendarEvents,
                schulaufgaben: state.schulaufgaben,
                exTemporalen: state.exTemporalen
            };
        },
        getStundenplanAndZeittafel: (state) => {
            if (state.stundenplan.getValue() === null) {
                store.dispatch('timetable/loadStundenplan');
                store.commit('dataUpdates/subscribe', {
                    dataType: 'stundenplan',
                    callback: () => store.dispatch('timetable/loadStundenplan')
                })
            }
            return {
                stundenplan: state.stundenplan,
                zeittafel: state.zeittafel
            };
        },
        getVertretungsplanAndMitteilungen: (state) => {
            if (state.vertretungsplan.getValue() === null) {
                store.dispatch('timetable/loadVertretungsplan');
                store.commit('dataUpdates/subscribe', {
                    dataType: 'vertretungsplan',
                    callback: () => store.dispatch('timetable/loadVertretungsplan')
                })
            }
            return {
                vertretungsplan: state.vertretungsplan,
                vertretungsplanMitteilungen: state.vertretungsplanMitteilungen
            };
        },
        getWeekdayName: (state) => (date) => {
            return state.weekdays[date.day() - 1];
        },
        getNextWeekday: (state) => (date) => {
            const nextDate = date.clone();
            do {
                nextDate.add(1, 'days');
            } while (state.weekdays[nextDate.day() - 1] === undefined)

            return nextDate;
        },
        getPrevWeekday: (state) => (date) => {
            const nextDate = date.clone();
            do {
                nextDate.subtract(1, 'days');
            } while (state.weekdays[nextDate.day() - 1] === undefined)

            return nextDate;
        }
    },
    mutations: {},
    actions: {
        loadEvents({state, rootGetters}) {
            return axios.get(rootGetters.getUrl('/api/termine'))
                .then(res => res.data)
                .then(data => {
                    state.calendarEvents.next(data.calendar);
                    state.schulaufgaben.next(data.leistungsnachweise.schulaufgaben);
                    state.exTemporalen.next(data.leistungsnachweise.ex_temporalen);
                })
                .catch(console.log);
        },
        loadStundenplan({state, rootGetters}) {
            return axios.get(rootGetters.getUrl('/api/stundenplan'))
                .then(res => res.data)
                .then(data => {
                    state.stundenplan.next(data.data);
                    state.zeittafel.next(data.zeittafel);
                })
                .catch(console.log);
        },
        loadVertretungsplan({state, rootGetters}) {
            return axios.get(rootGetters.getUrl('/api/vertretungsplan'))
                .then(res => res.data)
                .then(data => {
                    state.vertretungsplan.next(data.data);
                    state.vertretungsplanMitteilungen.next(data.mitteilungen);
                })
                .catch(console.log);
        }
    },
    modules: {}
}
