<template>
  <banner text="Stundenplan"/>

  <sui-segment raised inverted color="red" v-if="vertretungsplanForDate.length">
    Vertretungsplan Mitteilungen beachten
  </sui-segment>

  <sui-segment v-if="loadingStundenplan">
    <p>Stundenplan wird geladen</p>
    <loader-local :active="true"/>
  </sui-segment>

  <sui-segment v-if="loadingVertretungsplan">
    <p>Vertretungsplan wird geladen</p>
    <loader-local :active="true"/>
  </sui-segment>

  <sui-table unstackable definition v-if="stundenplan && zeittafel">
    <sui-table-header>
      <sui-table-row>
        <sui-table-header-cell/>
        <sui-table-header-cell>
          <div class="weekday-container">
            <sui-icon name="chevron circle left" class="pointer" @click="decDate()"/>
            <div class="date-container">
              <div>{{ weekdayName }}</div>
              <small>{{ date.format('DD.MM.YYYY') }}</small>
            </div>
            <sui-icon name="chevron circle right" class="pointer" @click="incDate()"/>
          </div>
        </sui-table-header-cell>
      </sui-table-row>
    </sui-table-header>

    <sui-table-body>
      <sui-table-row v-for="(zeittafelEntry, index) in zeittafel" :key="index">
        <sui-table-cell collapsing>
          <span class="ui small text">
            {{ zeittafelEntry.name }} {{ zeittafelEntry.value }}
          </span>
        </sui-table-cell>
        <sui-table-cell text-align="center" :class="{ warning: vertretungInHour(zeittafelEntry.hour) }">
          <div v-for="(stundenplanEntry, index) in getStundenplanEntriesByDayAndHour(weekday, zeittafelEntry.hour)"
               :key="index">
            {{ stundenplanEntry.uf }}
            <span v-if="stundenplanEntry.room">({{ stundenplanEntry.room }})</span>
          </div>
        </sui-table-cell>
      </sui-table-row>
    </sui-table-body>
  </sui-table>

  <sui-table unstackable compact color="yellow" v-if="vertretungsplanForDate.length">
    <sui-table-header>
      <sui-table-row>
        <sui-table-header-cell colspan="5">
          Vertretungen
        </sui-table-header-cell>
      </sui-table-row>
    </sui-table-header>

    <sui-table-body>
      <sui-table-row text-align="center">
        <sui-table-cell>Std.</sui-table-cell>
        <sui-table-cell>Betrifft</sui-table-cell>
        <sui-table-cell>Vertretung</sui-table-cell>
        <sui-table-cell>Fach</sui-table-cell>
        <sui-table-cell>Raum</sui-table-cell>
      </sui-table-row>
      <template v-for="(vertretung, index) in vertretungsplanForDate" :key="index">
        <sui-table-row warning text-align="center">
          <sui-table-cell :rowspan="vertretung.reason || vertretung.text ? 2 : 1">
            <b>{{ zeittafelEntryByHour(vertretung.hour).name }}</b>
          </sui-table-cell>
          <sui-table-cell>{{ vertretung.abs_teacher }}</sui-table-cell>
          <sui-table-cell>
            {{ vertretung.vertr_teacher && vertretung.vertr_teacher !== vertretung.abs_teacher ? vertretung.vertr_teacher : '-' }}
          </sui-table-cell>
          <sui-table-cell>{{ vertretung.uf }}</sui-table-cell>
          <sui-table-cell>{{ vertretung.room }}</sui-table-cell>
        </sui-table-row>
        <sui-table-row warning v-if="vertretung.reason || vertretung.text">
          <sui-table-cell colspan="4">
            <span v-if="vertretung.reason && vertretung.reason !=='-'">{{ vertretung.reason }}</span>
            <span v-if="vertretung.reason && vertretung.reason !=='-' && vertretung.text"> - </span>
            <span v-if="vertretung.text">{{ vertretung.text }}</span>
          </sui-table-cell>
        </sui-table-row>
      </template>
    </sui-table-body>
  </sui-table>

  <sui-table unstackable compact color="red" v-if="vertretungsplanMitteilungenForDate.length">
    <sui-table-header>
      <sui-table-row>
        <sui-table-header-cell>
          Mitteilungen
        </sui-table-header-cell>
      </sui-table-row>
    </sui-table-header>

    <sui-table-body>
      <template v-for="(mitteilung, index) in vertretungsplanMitteilungenForDate" :key="index">
        <sui-table-row v-if="mitteilung.text">
          <sui-table-cell>{{ mitteilung.text }}</sui-table-cell>
        </sui-table-row>
      </template>
    </sui-table-body>
  </sui-table>
</template>

<script>
import Banner from "../components/Banner";
import LoaderLocal from "../components/LoaderLocal";
import store from "../store";
import moment from "moment";

export default {
  name: "Stundenplan",
  components: {Banner, LoaderLocal},
  data: function () {
    return {
      date: moment(),
      stundenplan: null,
      zeittafel: null,
      vertretungsplan: null,
      vertretungsplanMitteilungen: null,
      loadingStundenplan: false,
      loadingVertretungsplan: false
    }
  },
  methods: {
    getStundenplanAndZeittafel: function () {
      this.loadingStundenplan = true;

      const {stundenplan, zeittafel} = store.getters['timetable/getStundenplanAndZeittafel'];
      stundenplan.subscribe((stundenplan) => {
        if (stundenplan !== null) {
          this.stundenplan = stundenplan;
          this.loadingStundenplan = false;
        }
      }, (error) => {
        // 401 redirects to login anyway
        if (error.response.status !== 401) {
          alert('Fehler beim Laden des Stundenplans.');
          this.loadingStundenplan = false;
        }
      });

      zeittafel.subscribe((zeittafel) => {
        this.zeittafel = zeittafel;
      });
    },
    getVertretungsplanAndMitteilungen: function () {
      this.loadingVertretungsplan = true;

      const {vertretungsplan, vertretungsplanMitteilungen} = store.getters['timetable/getVertretungsplanAndMitteilungen'];
      vertretungsplan.subscribe((vertretungsplan) => {
        if (vertretungsplan !== null) {
          this.vertretungsplan = vertretungsplan;
          this.loadingVertretungsplan = false;
        }
      }, (error) => {
        // 401 redirects to login anyway
        if (error.response.status !== 401) {
          alert('Fehler beim Laden des Vertretungsplans.');
          this.loadingVertretungsplan = false;
        }
      });

      vertretungsplanMitteilungen.subscribe((mitteilungen) => {
        this.vertretungsplanMitteilungen = mitteilungen;
      });
    },
    getStundenplanEntriesByDayAndHour: function (day, hour) {
      return this.stundenplan.filter((entry) => {
        return entry.day === day && entry.hour === hour;
      }).sort((a, b) => {
        return a.uf < b.uf ? -1 : 1;
      });
    },
    vertretungInHour(hour) {
      for (let entry of this.vertretungsplanForDate) {
        if (entry.hour === hour) {
          return true;
        }
      }
      return false;
    },
    zeittafelEntryByHour(hour) {
      if (!this.zeittafel) {
        return false;
      }
      for (let entry of this.zeittafel) {
        if (entry.hour === hour) {
          return entry;
        }
      }
      return false;
    },
    incDate: function () {
      this.date = store.getters['timetable/getNextWeekday'](this.date);
    },
    decDate: function () {
      this.date = store.getters['timetable/getPrevWeekday'](this.date);
    }
  },
  computed: {
    vertretungsplanForDate: function () {
      if (!this.vertretungsplan) {
        return [];
      }

      return this.vertretungsplan.filter((entry) => {
        return entry.date === this.date.format('YYYY-MM-DD');
      }).sort((a, b) => {
        return a.hour < b.hour ? -1 : 1;
      });
    },
    vertretungsplanMitteilungenForDate: function () {
      if (!this.vertretungsplanMitteilungen) {
        return [];
      }

      return this.vertretungsplanMitteilungen.filter((entry) => {
        return entry.date === this.date.format('YYYY-MM-DD');
      });
    },
    weekdayName: function () {
      return store.getters['timetable/getWeekdayName'](this.date);
    },
    weekday: function () {
      return this.date.day() - 1;
    }
  },
  created() {
    this.getStundenplanAndZeittafel();
    this.getVertretungsplanAndMitteilungen();
  }
}
</script>

<style scoped>
.weekday-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>