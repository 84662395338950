<template>
  <banner text="Chats"/>

  <sui-input size="mini" placeholder="Chat suchen..." icon="search" fluid v-model="search"/>

  <div v-if="authUserIsIpUser">
    <button class="mini ui blue button m-05" @click="toggleCreateChatModal">
      neuer Chat
    </button>

    <sui-modal v-model="createChatModalOpen">
      <sui-modal-header>Neuen Chat erstellen</sui-modal-header>
      <sui-modal-content>
        <form class="ui form" v-on:submit.prevent="createChat">
          <div v-bind:class="[creating ? 'ui loading search' : 'ui search']" class="flex-grow-1">
            <div class="ui fluid icon input">
              <input id="new-chat-name" type="text" placeholder="mein neuer Chat..." v-model="newChatForm.name">
              <i class="comment outline icon"></i>
            </div>

            <div class="ui toggle checkbox mt-1">
              <input type="checkbox" name="broadcast" v-model="newChatForm.broadcast">
              <label>
                Broadcast <br>
                <small>Nur Eigentümer kann Nachrichten verschicken</small>
              </label>
            </div>
          </div>
        </form>
      </sui-modal-content>
      <sui-modal-actions>
        <sui-button type="button" @click="toggleCreateChatModal">
          abbrechen
        </sui-button>
        <sui-button type="button" positive @click="createChat">
          Chat erstellen
        </sui-button>
      </sui-modal-actions>
    </sui-modal>
  </div>

  <loader-local :active="loading"/>

  <sui-list divided relaxed>
    <sui-list-item v-for="chat in chats" :key="chat.id">
      <sui-list-content>
        <sui-button icon @click="pinChat(chat)" class="right floated top aligned font-65" title="Chat anpinnen" id="pinChat" :class="!chat.latestMessage ? 'no-message' : '' ">
          <sui-icon :id="bluePinChatIcon(chat)" class="pinChat" name="pin icon large" />
        </sui-button>
        <router-link is="sui-list-content" :to="'/chat/' + chat.id" tag="button">
          <sui-list-header>
            <chat-icon :chat="chat"/>

            {{ chat.name }}
            <span class="ui circular red label ml-05" v-if="chat.unreadMessagesCount > 0">
              {{ chat.unreadMessagesCount }}
            </span>
          </sui-list-header>
          <sui-list-description>
            <template v-if="chat.latestMessage">
              <p class="text-preview" v-if="chat.latestMessage.text" v-html="sanitizeMessage(formatMessage(chat.latestMessage.text))"></p>
              <p class="text-preview" v-else-if="chat.latestMessage.file">
                <i class="paperclip icon"/>
                {{ chat.latestMessage.file }}
              </p>
              <template v-else>
                <p class="text-preview color-dark">
                  <i><i class="dont icon"></i>Diese Nachricht wurde gelöscht</i>
                </p>
              </template>
              <small>{{ dateDiffForHumans(chat.latestMessage.timestamp) }}</small>
            </template>
            <template v-else>
              <small>neuer Chat erstellt von {{ chat.owner.name }} {{ dateDiffForHumans(chat.createdAt) }}</small>
            </template>
            <template v-if="chat.userIsAdmin">
                <div class="float-right">
                  <sui-button icon @click="deleteChat(chat, $event)" class="right floated top aligned font-65 pb-0 pt-0" title="Chat löschen" id="deleteChat">
                    <sui-icon id="deleteChatIcon" name="trash icon large"  />
                  </sui-button>
                </div>
            </template>
          </sui-list-description>
        </router-link>
      </sui-list-content>
    </sui-list-item>
  </sui-list>
</template>

<script>
import LoaderLocal from "../components/LoaderLocal";
import Banner from "../components/Banner";
import store from '../store';
import moment from "moment";
import ChatIcon from "../components/ChatIcon";
import linkifyHtml from 'linkify-html';
import DOMPurify from 'isomorphic-dompurify';
import {formatText} from '../formatting.js';

export default {
  components: {ChatIcon, LoaderLocal, Banner},
  data: function () {
    return {
      loading: false,
      allChats: [],
      search: '',
      createChatModalOpen: false,
      creating: false,
      newChatForm: {
        name: '',
        broadcast: false
      }
    }
  },
  methods: {
    getChats: function () {
      this.loading = true;
      store.getters['chat/getChats']
          .subscribe((chats) => {
            if (chats !== null) {
              this.allChats = chats;
              this.sortChats();
              this.loading = false;
            }
          }, () => {
            alert('Fehler beim Laden der Chats.');
            this.loading = false;
          })
    },
    sortChats: function () {
      this.allChats.sort((a, b) => {
        const aPinned = a.pinned ? 1 : 0;
        const bPinned = b.pinned ? 1 : 0;
        if (aPinned !== bPinned) {
          return bPinned - aPinned;
        }
        const aDate = a.latestMessage ? a.latestMessage.timestamp : a.createdAt;
        const bDate = b.latestMessage ? b.latestMessage.timestamp : b.createdAt;
        if (aDate < bDate) {
          return 1;
        } else if (aDate > bDate) {
          return -1;
        }
        return 0;
      });
    },
    toggleCreateChatModal: function () {
      this.createChatModalOpen = !this.createChatModalOpen;
    },
    createChat: function () {
      this.creating = true;
      store.dispatch('chat/createChat', this.newChatForm)
          .then((chat) => {
            this.$router.push('/chat/' + chat.id + '?created=1');
          });
    },
    dateDiffForHumans: function (datetime) {
      return moment(datetime, 'X').fromNow();
    },
    deleteChat: function (chat, event) {
      event.preventDefault();
      event.stopPropagation();
      if (confirm('Möchten Sie diesen Chat wirklich für alle löschen?')) {
        store.dispatch('chat/deleteChat', chat);
      }
    },
    pinChat: function (chat) {
      chat.pinned = !chat.pinned;
      store.dispatch('chat/togglePinChat', chat);
      this.sortChats();
    },
    isUser: function (userId) {
      return store.getters['auth/isAuthUser'](userId);
    },
    bluePinChatIcon(chat) {
      return chat.pinned ? 'pinChatIconBlue' : 'pinChatIcon';
    },
    sanitizeMessage: function (message) {

      return DOMPurify.sanitize(
          linkifyHtml(message, {
            target: '_blank'
          }), {
            ALLOWED_TAGS: ['a', 'b', 'i', 'u'],
            ALLOWED_ATTR: ['href', 'target']
          });
    },
    formatMessage: function (message) {
      return formatText(message ? message.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;') : null);
    },
  },
  computed: {
    chats: function () {
      if (this.search) {
        return this.allChats.filter((chat) => {
          return chat.name.toUpperCase().includes(this.search.toUpperCase());
        });
      } else {
        return this.allChats;
      }
    },
    authUserIsIpUser: function () {
      return store.getters['auth/isIpUser'];
    }
  },
  created() {
    this.getChats();
  }
}
</script>

<style scoped>
#pinChat {
  color: #e8e8e8;
}

#deleteChat {
  color: #8f8f8f;
}

#pinChatIconBlue {
  color: #2185d0;
}

#deleteChat, #pinChat {
  background-color: white;
}

#deleteChat:active, #deleteChat:hover {
  color: red;
  transition: background-color ease !important;
}

.list {
  text-align: left;
}

.text-preview {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-message {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
</style>
