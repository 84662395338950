import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import FomanticUI from 'vue-fomantic-ui'
import 'fomantic-ui-css/semantic.min.css'
import './assets/utilities.css'
import './assets/fullcalendar.css' // manually import fullcalendar styles to avoid inline injections @see remove-fullcalendar-style-injections.sh
import './registerServiceWorker'

createApp(App)
  .use(store)
  .use(router)
  .use(FomanticUI)
  .mount('#app')
