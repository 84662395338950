import axios from 'axios';

export default {
    namespaced: true,
    state: {
        allChangelogEntries: null,
        currentVersion: null
    },
    getters: {},
    mutations: {
        setAllChangelogEntries(state, allChangelogEntries) {
            state.allChangelogEntries = allChangelogEntries;
        },
        setCurrentVersion(state, currentVersion) {
            if (currentVersion !== null) {
                const dateAndHoursArray = currentVersion.split(' ');
                const dateArray = dateAndHoursArray[0].split("-");
                const timeArray = dateAndHoursArray[1].split(":");
                state.currentVersion = dateArray[0] + '.' + dateArray[1] + '.' + dateArray[2] + '.' + timeArray[0] + timeArray[1] + timeArray[2];
            } else {
                state.currentVersion = null;
            }
        }
    },
    actions: {
        getAllChangelogEntries({commit, rootGetters}) {
            commit('setLoading', true, {root: true});

            return axios.get(rootGetters.getUrl('/api/changelog-entries'))
                .then(res => res.data)
                .then(entries => commit('setAllChangelogEntries', entries))
                .catch(() => commit('setAllChangelogEntries', null))
                .finally(() => commit('setLoading', false, {root: true}));
        },
        getCurrentVersion({commit, rootGetters, rootState}) {
            commit('config/removeSchoolFromServerUrl', null, {root: true});
            commit('setLoading', true, {root: true});

            axios.get(rootGetters.getUrl('/get-current-version'))
                .then(res => res.data)
                .then(data => commit('setCurrentVersion', data.current_version))
                .catch(() => commit('setCurrentVersion', null))
                .finally(() => {
                    commit('config/addSchoolToServerUrl', rootState.auth.school, {root: true});
                    commit('setLoading', false, {root: true});
                });
        }
    }
}