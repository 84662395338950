import store from "../store";

export default function setup() {
    if (!store.getters['auth/check'] || store.state.initialized) return;

    // Subscribe for push notifications including asking for permission
    store.dispatch('serviceWorker/subscribeForPush');

    return Promise.all([
        // Load navigation setup
        store.dispatch('navigation/loadSetup'),
        // Initialize Echo for the websocket connection
        store.dispatch('websocket/initEcho')
    ])
        .finally(() => store.state.initialized = true);
}