import {createStore} from 'vuex'
import auth from './modules/auth'
import config from './modules/config';
import chat from './modules/chat';
import chatRequest from './modules/chatRequest';
import websocket from './modules/websocket';
import timetable from "./modules/timetable";
import unterricht from "./modules/unterricht";
import hausaufgaben from "./modules/hausaufgaben";
import news from "./modules/news";
import changelog from './modules/changelog';
import dataUpdates from "./modules/dataUpdates";
import navigation from "./modules/navigation";
import serviceWorker from "./modules/serviceWorker";

export default createStore({
  state: {
    loading: {
      count: 0,
      active: false
    },
    initialized: false
  },
  getters: {
    getUrl: (state) => (uri) => {
      return state.config.serverUrl + uri;
    }
  },
  mutations: {
    setLoading(state, loading) {
      if (loading) {
        state.loading.count++;
      } else {
        state.loading.count--;
      }
      state.loading.active = state.loading.count > 0;
    }
  },
  actions: {},
  modules: {
    auth: auth,
    chat: chat,
    chatRequest: chatRequest,
    config: config,
    timetable: timetable,
    unterricht: unterricht,
    hausaufgaben: hausaufgaben,
    news: news,
    websocket: websocket,
    changelog: changelog,
    dataUpdates: dataUpdates,
    navigation: navigation,
    serviceWorker: serviceWorker
  }
})
