<template>

  <sui-segment>
    <h3>News</h3>

    <loader-local :active="loadingNews"/>

    <sui-list v-if="news.length > 0" divided relaxed>
      <sui-list-item v-for="(entry, index) of news" :key="index">
        <sui-list-content id="content">
          <sui-list-header>{{ entry.title }}</sui-list-header>
          <sui-list-description>{{ entry.content }}</sui-list-description>

          <attachment-list v-if="entry.file" :files="[entry.file]"/>
        </sui-list-content>
      </sui-list-item>
    </sui-list>

    <div v-else-if="!loadingNews">
      &empty; Keine aktuellen Nachrichten
    </div>
  </sui-segment>

</template>

<script>
import store from "../store";
import LoaderLocal from "./LoaderLocal";
import AttachmentList from "./AttachmentList";

export default {
  name: 'News',
  components: {LoaderLocal, AttachmentList},
  props: ['text', 'icon'],
  data: function () {
    return {
      news: [],
      loadingNews: false
    }
  },
  methods: {
    getNews: function () {
      this.loadingNews = true;
      store.getters['news/getNews']
          .subscribe((news) => {
            if (news !== null) {
              this.news = news;
              this.loadingNews = false;
            }
          }, (error) => {
            // 401 redirects to login anyway
            if (error.response.status !== 401) {
              alert('Fehler beim Laden der News.');
              this.loadingNews = false;
            }
          });
    }
  },
  created() {
    this.getNews();
  }
}
</script>

<style>
#content {
  overflow-wrap: anywhere;
}
</style>