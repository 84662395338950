import axios from 'axios';
import store from "../index"

export default {
    namespaced: true,
    state: {
        setup: {
            news: false,
            chats: false,
            unterricht: {
                content: false,
                homework: false
            },
            events: {
                calendarEvents: false,
                leistungsnachweise: false
            },
            timetable: {
                stundenplan: false,
                vertretungsplan: false
            }
        },
        loading: false,
        loaded: false
    },
    getters: {
        getSetup: (state) => {
            if (!state.loading && !state.loaded) {
                store.dispatch('navigation/loadSetup');
            }

            return state.setup;
        }
    },
    mutations: {},
    actions: {
        loadSetup({state, commit, rootGetters}) {
            commit('setLoading', true, {root: true});
            state.loading = true;

            return axios.get(rootGetters.getUrl('/api/nav'))
                .then(res => res.data)
                .then(setup => {
                    state.setup = Object.assign(state.setup, setup);
                    state.loaded = true;
                })
                .finally(() => {
                    state.loading = false;
                    commit('setLoading', false, {root: true});
                });
        }
    },
    modules: {}
}
