<template>

  <sui-segment class="asam-yellow">
    <sui-header>
      <sui-icon v-if="icon" :name="icon" size="big"/>
      {{ text }}

      <slot />
    </sui-header>
  </sui-segment>

</template>

<script>
export default {
  name: "Banner",
  props: ['text', 'icon']
}
</script>