import store from "../store";

export default function resolveSchool(to) {
    // If route has school as parameter then set this one, else load school from cookie
    if (to.params.school) {
        return store.dispatch('auth/setSchool', to.params.school);
    } else if (to.query.school) {
        return store.dispatch('auth/setSchool', to.query.school);
    } else {
        return store.dispatch('auth/loadSchool')
            .then(() => {
                // Stop if school cannot be identified
                if (store.state.auth.school === null) {
                    throw new Error('School could not be identified.');
                }
            });
    }
}