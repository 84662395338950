<template>

  <template v-for="(hausaufgabe, index) in hausaufgabenList" :key="hausaufgabe.id">
    <template v-if="(this.active != 'Vergangen' && this.active != 'Heute')">
      <template v-if="(!hausaufgabe.completed)">
        <template v-if="showHeading(index)">
          <div class="ui header medium block">
            {{ showHeading(index) }}
          </div>
        </template>
      </template>
    </template>

    <sui-grid :columns="4" textAlign="left" celled :id="hausaufgabe.id">
      <sui-grid-row>
        <sui-grid-column :width="3" textAlign="center" class="pr-0"
          :class="{ paddingBottom: hausaufgabe.back || hausaufgabe.files.length > 0 }">
          <div class="textLayout" :title="hausaufgabe.subject.long">
            <b> {{ hausaufgabe.subject.short }} </b>
          </div>
          <small> {{ formatDateGerman(hausaufgabe.date) }} </small>
        </sui-grid-column>

        <sui-grid-column :width="8">
          <div class="textLayout pointer pr-0" @click="showDetails(hausaufgabe.id)">
             {{ hausaufgabe.homework }}
          </div>
        </sui-grid-column>

        <sui-grid-column :width="3" class="pr-0">
          <div v-if="(hausaufgabe.due_at != null)">
            bis {{ weekday(hausaufgabe.due_at) }}, {{ formatDateGerman(hausaufgabe.due_at) }}
          </div>
        </sui-grid-column>

        <sui-grid-column :width="2" class="pl-05">
          <template v-if="!hausaufgabe.completed && !hausaufgabe.back">
            <button class="circular ui icon button font-05 valign-middle"
              @click="toggleCompleted(hausaufgabe.id), show = !show">
              <i class="icon check font-175 button-bg-color"></i>
            </button>
          </template>
          <template v-if="hausaufgabe.back && !hausaufgabe.completed">
            <button class="circular ui icon button font-05 valign-middle"
              @click="showDetails(hausaufgabe.id)">
              <i class="icon chevron right font-175"></i>
            </button>
          </template>
          <template v-if="hausaufgabe.submissions.length > 0">
            <button class="circular ui icon button bg-white font-05 valign-middle"
              @click="showDetails(hausaufgabe.id)">
              <i class="icon check green font-2" title="Hausaufgabe wurde online eingereicht"></i>
            </button>
          </template>
          <template v-else-if="hausaufgabe.completed">
            <button class="circular ui icon button green font-05 valign-middle"
              @click="toggleCompleted(hausaufgabe.id)">
              <i class="icon check font-175 valign-bottom"></i>
            </button>
          </template>
        </sui-grid-column>
      </sui-grid-row>

      <sui-grid-row class="d-inline-block" v-if="hausaufgabe.back || hausaufgabe.files.length > 0">
        <sui-grid-column :width="3" class="secondRow pr-0">
        </sui-grid-column>

        <sui-grid-column :width="8" class="secondRow" @click="showDetails(hausaufgabe.id)">
          <div v-if="hausaufgabe.files.length > 0">
            <div class="d-inline-flex pointer">
              <sui-icon name="paperclip" class="font-125" title="Anhang vorhanden" />
              {{ hausaufgabe.files.length }}
            </div>
          </div>
        </sui-grid-column>

        <sui-grid-column :width="3" class="secondRow pr-0">
          <template v-if="!hausaufgabe.completed && hausaufgabe.back">
              <sui-icon name="upload" class="font-125" title="Online-Abgabe" />
            </template>
          <template v-for="submission of hausaufgabe.submissions" :key="submission">
            <template v-if="hausaufgabe.submissions.length > 0 && submission.teacherCorrectedAt == null">
              <sui-icon name="upload green" class="font-125" title="Online-Abgabe erfolgreich" />
            </template>
            <template v-if="submission.teacherCorrectedAt != null">
              <sui-icon-group size="large" title="Korrektur vorhanden">
                <sui-icon name="file alternate outline" />
                <sui-icon name="eye" corner="bottom right" class="font-05 left-07" />
              </sui-icon-group>
            </template>
          </template>
        </sui-grid-column>

        <sui-grid-column :width="2" class="secondRow">
        </sui-grid-column>
      </sui-grid-row>

    </sui-grid>

  </template>
</template>

<script>
import moment from 'moment';
import store from "../store";

export default {
  name: 'HausaufgabenList',
  props: ['hausaufgabenList', 'active', 'dueDateMap'],
  methods: {
    showHeading: function (index) {
      if (index == this.dueDateMap['today']) {
        return "Abgabe heute fällig";
      }
      if (index == this.dueDateMap['tomorrow']) {
        return "Abgabe bis morgen";
      }
      if (index == this.dueDateMap['dayAfterTomorrow']) {
        return "Abgabe bis übermorgen";
      }
      if (index == this.dueDateMap['laterTime']) {
        return "Abgabe in 3 Tagen oder später";
      }
      if (index == this.dueDateMap['noDueDate']) {
        return "Rückgabedatum nicht vermerkt";
      }
    },
    showDetails: function (id) {
      return this.$router.push('/hausaufgaben/' + id);
    },
    weekday: function (dueDate) {
      return new Date(dueDate).toLocaleString('de-de', { weekday: 'short' });
    },
    formatDateGerman: function (dateString) {
      return moment(dateString).format('DD.MM.');
    },
    toggleCompleted: function (id) {
      store.dispatch('hausaufgaben/toggleCompleted', id);
    },
  },
}
</script>

<style>

.paddingBottom {
  padding-bottom: 0em !important;
}


.ui.celled.grid>.row {
  box-shadow: 0 0 0 0 white !important;
}

.ui.grid>.row {
  align-items: baseline !important;
}

.ui.celled.grid>.column:not(.row),
.ui.celled.grid>.row>.column {
  box-shadow: none !important;
}

.secondRow {
  padding-top: 0px !important;
}

.textLayout {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
</style>